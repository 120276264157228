import React, { useEffect, useState } from 'react';
import Main from '../../../elements/public/main';
import { getClassList } from '../services';
import { Class } from '../../../services/models/classes.model';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { toTimeZoneSimple } from '../../../services/date.service';

const TaClassList = () => {
    const [data, setData] = useState<Class[] | undefined>(undefined);
    useEffect(() => {
        const loadClassList = async () => {
            try {
                const response = await getClassList();
                setData(response.data)
            }
            catch {

            }
        }

        loadClassList();

    }, [])
    return (
        <Main>
           {data && (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Class Name</TableCell>
                  <TableCell align="left">Enrollment Key</TableCell>
                  <TableCell align="left">Instructor</TableCell>
                  <TableCell align="left">Subject Area</TableCell>
                  <TableCell align="left">Student Level</TableCell>
                  <TableCell align="left">Start Date</TableCell>
                  <TableCell align="left">End Date</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row: Class) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      <Link to={"/ta/classDetails/" + row.id}>{row.className}</Link>
                    </TableCell>
                    <TableCell align="left">{row.enrollkey}</TableCell>
                    <TableCell align="left">{row?.instructor}</TableCell>
                    <TableCell align="left">{row.subjectArea}</TableCell>
                    <TableCell align="left">{row.studentLevel}</TableCell>
                    <TableCell align="left">
                      {toTimeZoneSimple(row.startDate)}
                    </TableCell>
                    <TableCell align="left">
                      {toTimeZoneSimple(row.endDate)}
                    </TableCell>
                    <TableCell align="left">
                     
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
             
            </Table>
          </TableContainer>
        )}

        </Main>
    );
};

export default TaClassList;