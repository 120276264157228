import React, { useState } from "react";
import { useSelector } from "react-redux";
import { User } from "../../services/models/auth-token.model";
import { useDispatch } from "react-redux";
import { validate } from "../../actions/auth";
import Main from "../../elements/public/main";
import PasswordTwoToneIcon from "@mui/icons-material/PasswordTwoTone";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ResetPassword from "./ResetPassword";

const UserProfile: React.FC = () => {
  const dispatch = useDispatch();
  const [openPass, setOpenPass] = useState<boolean>(false);
  // @ts-ignore
  dispatch(validate());
  const user: User | undefined = useSelector<any, User | undefined>(
    (state) => state.auth.user
  );
  return (
    <Main>
      <Grid container component="main" sx={{ height: "80vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={12}
          md={12}
          sx={{
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/bg-2.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {user && (
            <Grid container>
              <Grid item sm={6} md={4}>
                <Container
                  sx={{
                    color: "white",
                    position: "relative",
                    top: "50%",
                    transform: "translateY(-12%)",
                  }}
                >
                  <Card sx={{ maxWidth: 345 }}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe" src={user.imageUrl}>
                          {user.firstName.substring(0, 1)}
                        </Avatar>
                      }
                      title="Profile"
                      subheader={user.firstName + " " + user.lastName}
                    />

                    <CardContent>
                      <Typography variant="body2">
                        Username: {user.username}
                      </Typography>
                      <Typography variant="body2">
                        Email: {user.email}
                      </Typography>
                    </CardContent>
                    {user.authProvider === "local" && (
                      <CardActions disableSpacing>
                        <Tooltip title="Change Password">
                          <IconButton
                            aria-label="Change Password"
                            sx={{ ml: "auto" }}
                            onClick={() => setOpenPass(true)}
                          >
                            <PasswordTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      </CardActions>)}
                  </Card>
                </Container>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <ResetPassword open={openPass} handleClose={() => setOpenPass(false)} />
    </Main>
  );
};

export default UserProfile;
