import React from "react";
import { Toolbar, IconButton, Typography, Divider, Box } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { logout } from "../../actions/auth";
import { clearMessage } from "../../actions/message";
// import { setDrawerOpen, setDrawerClose } from "../../actions/drawer";
import { setDrawerOpen, setDrawerClose } from "../../store/Drawer/actions";
import { history } from "../../helpers/history";
import { connect } from "react-redux";
import EventBus from "../../common/EventBus";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  UsersMenu,
  GuestUsersMenu,
  InstUserMenu,
  StudentUserMenu,
  StudentGuideMenu,
  InstGuideMenu,
  EducationCoordinatorMenu,
  AdminMenu,
  UserSupportMenu,
  VideoGuideMenu,
  TAMenu,
} from "./menu";
import MenuDrawer from "./itemDrawer";
import { AppBar, Drawer, DrawerHeader } from "./styled-components";
import { validateExpiry } from "../../services/auth.service";

class Slider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      showInstBoard: false,
      currentUser: undefined,
      isSupport: process.env.REACT_APP_SUPPORT === "true",
    };

    history.listen((locations) => {
      props.dispatch(clearMessage());
    });
  }

  componentDidMount() {
    const user = this.props.user;
    if (user) {
      if (validateExpiry()) {
        this.setState({
          currentUser: user,
          showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
          showAdminBoard: user.roles.includes("ROLE_ADMIN"),
          showInstBoard: user.roles.includes("ROLE_INSTRUCTOR"),
          showStudentBoard: user.roles.includes("ROLE_STUDENT"),
          showTaBoard: user.roles.includes("ROLE_TEACHING_ASSISTANT"),

          showEducationCoordinatorBoard: user.roles.includes(
            "ROLE_EDUCATION_COORDINATOR"
          ),
        });
      }
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      showInstBoard: false,
      showStudentBoard: false,
      showTaBoard: false,
      currentUser: undefined,
    });
  }
  handleDrawerOpen() {
    this.props.dispatch(setDrawerOpen());
  }
  handleDrawerClose() {
    this.props.dispatch(setDrawerClose());
  }

  render() {
    const {
      currentUser,
      showInstBoard,
      showStudentBoard,
      showEducationCoordinatorBoard,
      showAdminBoard,
      showTaBoard
    } = this.state;
    const { isDrawerOpen, classDetails } = this.props;
    // Log classDetails here
    return (
      <Box sx={{ display: "flex" }}>
        {/* @ts-ignore */}
        <AppBar position="sticky" open={isDrawerOpen}>
          <Toolbar>
            {isDrawerOpen === false && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={this.handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {" "}
              {isDrawerOpen === true ? (
                "Antecedent"
              ) : (
                <>
                  Antecedent Writing Analytics{" "}
                  {classDetails &&
                    classDetails.className !== "" &&
                    `- ${classDetails.className}`}
                </>
              )}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={isDrawerOpen} sx={{ width: 0 }}>
          <DrawerHeader>
            <IconButton onClick={this.handleDrawerClose}>
              {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          {showStudentBoard && (
            <>
              <Divider /> <MenuDrawer list={StudentUserMenu} />
            </>
          )}
          {showInstBoard && (
            <>
              {" "}
              <Divider />
              <MenuDrawer list={InstUserMenu} />
            </>
          )}
          {showTaBoard && (
            <>
              {" "}
              <Divider />
              <MenuDrawer list={TAMenu} />
            </>
          )}
          {showEducationCoordinatorBoard && (
            <>
              <Divider /> <MenuDrawer list={EducationCoordinatorMenu} />
            </>
          )}
          {showAdminBoard && (
            <>
              <Divider /> <MenuDrawer list={AdminMenu} />
            </>
          )}
          <Divider />
          {showStudentBoard && (
            <>
              <MenuDrawer list={StudentGuideMenu} />
            </>
          )}
          {showInstBoard && (
            <>
              {" "}
              <MenuDrawer list={InstGuideMenu} />
            </>
          )}
          {(showStudentBoard || showInstBoard) && (
            <MenuDrawer list={VideoGuideMenu} />
          )}

          <Divider />
          {this.state.isSupport && <MenuDrawer list={UserSupportMenu} />}
          {currentUser ? (
            <>
              <Divider />
              <MenuDrawer list={UsersMenu} />
            </>
          ) : (
            <>
              <Divider />
              <MenuDrawer list={GuestUsersMenu} />
            </>
          )}
        </Drawer>
      </Box>
    );
  }
}

function mapStateToProps(state: any) {
  const { user } = state.auth;
  const { isDrawerOpen } = state.drawer;
  const { classDetails } = state;
  return {
    user,
    isDrawerOpen,
    classDetails,
  };
}

export default connect(mapStateToProps)(Slider);
