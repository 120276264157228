import { LetterGradesType } from "../../containers/instructor/CreateCourse/model";
import {
  getAssignmentTimeMetrics,
  getSubmissionLogs,
  setGrade,
  setToEvaluate,
  setToFinal,
  setToReOpen,
  endCommenting,
  submitDraft,
  draftGrading,
  setDraftPermission,
  setAltGrade,
  draftAltGrading,
} from "../../services/assignments.service";
import {
  addNewFeedback,
  getSubmissionDataAsync,
  updateFeedbacks,
} from "../../services/data.service";
import {
  FeedBack,
  SubmissionDetails,
} from "../../services/models/assignments.model";
import { TimeMetrics } from "../../services/models/classes.model";
import { setIsLoaded, setIsLoading } from "../UIActions/Loading/actions";
import { setSnackbarMessage } from "../UIActions/Snackbar/actions";
import { AlertTypes } from "../UIActions/Snackbar/model";
import { apiIds } from "./constants";
import { SubmissionLogs } from "./model";

export const GET_SUBMISSION_DATA_SUCCESS = "GET_SUBMISSION_DATA_SUCCESS";
export const GET_TIME_METRICS_SUCCESS = "GET_TIME_METRICS_SUCCESS";
export const RE_OPEN_SUBMISSION_SUCCESS = "RE_OPEN_SUBMISSION_SUCCESS";
export const FINALIZE_SUBMISSION_SUCCESS = "FINALIZE_SUBMISSION_SUCCESS";
export const EVALUATE_SUBMISSION_SUCCESS = "EVALUATE_SUBMISSION_SUCCESS";
export const GRADE_SUBMISSION_SUCCESS = "GRADE_SUBMISSION_SUCCESS";
export const GET_SUBMISSION_LOGS_SUCCESS = "GET_SUBMISSION_LOGS_SUCCESS";
export const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS";
export const END_COMMENTING = "END_COMMENTING";
export const DRAFT_SUBMIT = "DRAFT_SUBMIT";
export const DRAFT_GRADING = "DRAFT_GRADING";
export const DRAFT_ALT_GRADING = "DRAFT_ALT_GRADING";
export const SET_DRAFT_PERMISSION = "SET_DRAFT_PERMISSION";

export type ActionType =
  | GetSubmissionData
  | ReOpenSubmissionSuccess
  | GetTimeMetricsSuccess
  | FinalizeSubmissionSuccess
  | EvaluateSubmissionSuccess
  | GetSubmissionLogsSuccess
  | GradeSubmissionSuccess
  | AddFeedbackSuccess
  | EndCommentingSuccess
  | DraftSubmitSuccess
  | DraftGradeSuccess
  | SetDraftPermissionSuccess
  | DraftAltGradeSuccess;

interface GetSubmissionData {
  type: typeof GET_SUBMISSION_DATA_SUCCESS;
  payload: {
    submissionData: any;
  };
}

export const getSubmissionDataSuccess = (
  submissionData: any
): GetSubmissionData => ({
  type: GET_SUBMISSION_DATA_SUCCESS,
  payload: {
    submissionData,
  },
});

export const getSubmissionDataAction =
  (submissionId: string) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.getSubmissionData));
    try {
      const submission = await getSubmissionDataAsync(submissionId);
      dispatch(getSubmissionDataSuccess(submission.data));
      dispatch(setIsLoaded(apiIds.getSubmissionData));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.getSubmissionData));
      throw err;
    }
  };

interface GetTimeMetricsSuccess {
  type: typeof GET_TIME_METRICS_SUCCESS;
  payload: {
    timeMetrics: TimeMetrics;
  };
}

export const getTimeMetricsSuccess = (
  timeMetrics: TimeMetrics
): GetTimeMetricsSuccess => ({
  type: GET_TIME_METRICS_SUCCESS,
  payload: {
    timeMetrics,
  },
});

export const getTimeMetrics =
  (submissionId: string) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.getTimeMetrics));
    try {
      const timeMetrics = await getAssignmentTimeMetrics(submissionId);
      dispatch(getTimeMetricsSuccess(timeMetrics.data));
      dispatch(setIsLoaded(apiIds.getTimeMetrics));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.getTimeMetrics));
      throw err;
    }
  };

// REOPEN submission start

interface ReOpenSubmissionSuccess {
  type: typeof RE_OPEN_SUBMISSION_SUCCESS;
  payload: {
    submissionData: SubmissionDetails;
  };
}
export const reOpenSubmissionSuccess = (
  submissionData: SubmissionDetails
): ReOpenSubmissionSuccess => ({
  type: RE_OPEN_SUBMISSION_SUCCESS,
  payload: {
    submissionData,
  },
});

export const reopenSubmissionAction =
  (submissionId: string, status: boolean = true) =>
  async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.reOpenSubmission));
    try {
      const submission = await setToReOpen(submissionId, status);
      dispatch(reOpenSubmissionSuccess(submission.data));
      dispatch(setIsLoaded(apiIds.reOpenSubmission));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.reOpenSubmission));
      throw err;
    }
  };

// REOPEN submission end

// Finalize submission start
interface FinalizeSubmissionSuccess {
  type: typeof FINALIZE_SUBMISSION_SUCCESS;
  payload: {
    submissionData: SubmissionDetails;
  };
}
export const finalizeSubmissionSuccess = (
  submissionData: SubmissionDetails
): FinalizeSubmissionSuccess => ({
  type: FINALIZE_SUBMISSION_SUCCESS,
  payload: {
    submissionData,
  },
});

export const finalizeSubmissionAction =
  (submissionId: string) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.finalizeSubmission));
    try {
      const submission = await setToFinal(submissionId);
      dispatch(finalizeSubmissionSuccess(submission.data));
      dispatch(setIsLoaded(apiIds.finalizeSubmission));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.finalizeSubmission));
      throw err;
    }
  };

// Finalize submission end

// Evaluate submission start
interface EvaluateSubmissionSuccess {
  type: typeof EVALUATE_SUBMISSION_SUCCESS;
  payload: {
    submissionData: SubmissionDetails;
  };
}
export const evaluateSubmissionSuccess = (
  submissionData: SubmissionDetails
): EvaluateSubmissionSuccess => ({
  type: EVALUATE_SUBMISSION_SUCCESS,
  payload: {
    submissionData,
  },
});

export const evaluateSubmissionAction =
  (submissionId: string) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.evaluateSubmission));
    try {
      const submission = await setToEvaluate(submissionId);
      dispatch(evaluateSubmissionSuccess(submission.data));
      dispatch(setIsLoaded(apiIds.evaluateSubmission));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.evaluateSubmission));
      throw err;
    }
  };

// Evaluate submission end

// Grade submission start
interface GradeSubmissionSuccess {
  type: typeof GRADE_SUBMISSION_SUCCESS;
  payload: {
    submissionData: SubmissionDetails;
  };
}
export const gradeSubmissionSuccess = (
  submissionData: SubmissionDetails
): GradeSubmissionSuccess => ({
  type: GRADE_SUBMISSION_SUCCESS,
  payload: {
    submissionData,
  },
});

export const gradeSubmissionAction =
  (submissionId: string, grade: number) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.gradeSubmission));
    try {
      const submission = await setGrade(submissionId, grade);
      dispatch(gradeSubmissionSuccess(submission.data));
      dispatch(setIsLoaded(apiIds.gradeSubmission));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.gradeSubmission));
      throw err;
    }
  };

  export const altGradeSubmissionAction =
  (submissionId: string, grade: LetterGradesType) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.gradeSubmission));
    try {
      const submission = await setAltGrade(submissionId, grade);
      dispatch(gradeSubmissionSuccess(submission.data));
      dispatch(setIsLoaded(apiIds.gradeSubmission));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.gradeSubmission));
      throw err;
    }
  };

// Grade submission end

// Get submission logs start
interface GetSubmissionLogsSuccess {
  type: typeof GET_SUBMISSION_LOGS_SUCCESS;
  payload: {
    submissionLogs: SubmissionLogs;
  };
}
export const getSubmissionLogsSuccess = (
  submissionLogs: SubmissionLogs
): GetSubmissionLogsSuccess => ({
  type: GET_SUBMISSION_LOGS_SUCCESS,
  payload: {
    submissionLogs,
  },
});

export const getSubmissionLogsAction =
  (submissionId: string) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.getSubmissionLogs));
    try {
      const submission = await getSubmissionLogs(submissionId);
      dispatch(getSubmissionLogsSuccess(submission.data));
      dispatch(setIsLoaded(apiIds.getSubmissionLogs));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.getSubmissionLogs));
      throw err;
    }
  };

interface AddFeedbackSuccess {
  type: typeof ADD_FEEDBACK_SUCCESS;
  payload: {
    feedbacks: FeedBack[];
  };
}

export const addFeedbackSuccess = (
  feedbacks: FeedBack[]
): AddFeedbackSuccess => ({
  type: ADD_FEEDBACK_SUCCESS,
  payload: { feedbacks },
});

export const addFeedbackAction =
  (submissionId: string, comment: string,  draftId:string) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.addNewFeedback));
    try {
      const feedbacks = await addNewFeedback(submissionId, comment, draftId);
      dispatch(setIsLoaded(apiIds.addNewFeedback));
      dispatch(addFeedbackSuccess(feedbacks.data));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.addNewFeedback));
      throw err;
    }
  };

export const updateFeedbacksAction = (submissionId: string,feedbacks: FeedBack[]) =>async(dispatch: any)=>{
  dispatch(setIsLoading(apiIds.addNewFeedback));
    try {
      const response = await updateFeedbacks(submissionId, feedbacks);
      dispatch(setIsLoaded(apiIds.addNewFeedback));
      dispatch(addFeedbackSuccess(response.data));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.addNewFeedback));
      // console.log(err)
      throw err;
    }
}

// End commenting actions and methods

interface EndCommentingSuccess {
  type: typeof END_COMMENTING;
  payload: {
    submissionData: any;
  };
}

export const endCommentingSuccess = (
  submissionData: any
): EndCommentingSuccess => ({
  type: END_COMMENTING,
  payload: {
    submissionData,
  },
});

export const endCommentingAction =
  (submissionId: string, draftSubmissionId: string) =>
  async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.endCommenting));
    try {
      const submission = await endCommenting(submissionId, draftSubmissionId);
      dispatch(endCommentingSuccess(submission.data));
      dispatch(setIsLoaded(apiIds.endCommenting));
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.SUCCESS,
          showSnackBar: true,
          message: `Commented upon version saved.`,
        })
      );
    } catch (err) {
      dispatch(setIsLoaded(apiIds.endCommenting));
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `Failed to save the commented upon version.`,
        })
      );
    }
  };
// Draft Submit
interface DraftSubmitSuccess {
  type: typeof DRAFT_SUBMIT;
  payload: {
    submissionData: any;
  };
}

export const draftSubmitSuccess = (
  submissionData: any
): DraftSubmitSuccess => ({
  type: DRAFT_SUBMIT,
  payload: {
    submissionData,
  },
});

export const draftSubmitAction =
  (submissionId: string, draftId: string) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.draftSubmit));
    try {
      const submission = await submitDraft(submissionId, draftId);
      dispatch(draftSubmitSuccess(submission.data));
      dispatch(setIsLoaded(apiIds.draftSubmit));
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.SUCCESS,
          showSnackBar: true,
          message: `Draft submitted successfully.`,
        })
      );
    } catch (err) {
      dispatch(setIsLoaded(apiIds.draftSubmit));
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `An error occurred during submission.`,
        })
      );
    }
  };

// Update draft grade
interface DraftGradeSuccess {
  type: typeof DRAFT_GRADING;
  payload: {
    id: string;
    grade: number;
  };
}

export const draftGradeSuccess = (
  id: string,
  grade: number
): DraftGradeSuccess => ({
  type: DRAFT_GRADING,
  payload: {
    id,
    grade,
  },
});

export const draftGradeAction =
  (id: string, grade: number) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.draftGrading));
    try {
      const response = await draftGrading(id, grade);
      if (response.status !== 200) {
        throw new Error("Error");
      }
      dispatch(draftGradeSuccess(id, grade));
      dispatch(setIsLoaded(apiIds.draftSubmit));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.draftSubmit));
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `An error occurred during grading.`,
        })
      );
    }
  };


  interface DraftAltGradeSuccess {
    type: typeof DRAFT_ALT_GRADING;
    payload: {
      id: string;
      alternateGrade: LetterGradesType;
    };
  }
  
  export const draftAltGradeSuccess = (
    id: string,
    alternateGrade: LetterGradesType
  ): DraftAltGradeSuccess => ({
    type: DRAFT_ALT_GRADING,
    payload: {
      id,
      alternateGrade,
    },
  });
  
  export const draftAltGradeAction =
    (id: string, alternateGrade: LetterGradesType) => async (dispatch: any) => {
      dispatch(setIsLoading(apiIds.draftGrading));
      try {
        const response = await draftAltGrading(id, alternateGrade);
        if (response.status !== 200) {
          throw new Error("Error");
        }
        dispatch(draftAltGradeSuccess(id, alternateGrade));
        dispatch(setIsLoaded(apiIds.draftSubmit));
      } catch (err) {
        dispatch(setIsLoaded(apiIds.draftSubmit));
        dispatch(
          setSnackbarMessage({
            type: AlertTypes.ERROR,
            showSnackBar: true,
            message: `An error occurred during grading.`,
          })
        );
      }
    };

interface SetDraftPermissionSuccess {
  type: typeof SET_DRAFT_PERMISSION;
  payload: {
    submissionData: any;
  };
}

export const setDraftPermissionSuccess = (
  submissionData: any
): SetDraftPermissionSuccess => ({
  type: SET_DRAFT_PERMISSION,
  payload: {
    submissionData,
  },
});

export const setDraftPermissionAction =
  (submissionId: string, draftSubmissionId: string, status: boolean = true) =>
  async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.draftPermission));
    try{
      const submission = await(setDraftPermission(submissionId,draftSubmissionId,status));
      dispatch(setDraftPermissionSuccess(submission.data));
      dispatch(setIsLoaded(apiIds.draftSubmit));
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.SUCCESS,
          showSnackBar: true,
          message: `Draft permission status changed successfully.`,
        })
      );
    }
    catch(err){
      dispatch(setIsLoaded(apiIds.draftPermission));
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `An error occurred during this task.`,
        })
      );
    }
  };
