import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { validate } from '../../../actions/auth';
import { getClassDetailsAction } from '../../../store/ClassDetails/actions';
import { useParams } from 'react-router-dom';
import { AssignmentInit, ClassDetails } from '../../../store/ClassDetails/model';
import Main from '../../../elements/public/main';
import { useSelector } from 'react-redux';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { tabs } from './model';
import Details from './Details';
import { DateRange, GraphsProps, initDateRange, initGraphProps, TabType } from '../../instructor/ClassDetails/Assignments/Analytics/models';
import ClassDashboard from '../../instructor/ClassDetails/Assignments/Analytics/ClassDashboard';
import Assignments from './Assignments/Assignments';
import StudentsList from '../../instructor/ClassDetails/Students/StudentsList';


const TAClassDetails: React.FC = () => {

    const { id } = useParams();
    const dispatch = useDispatch<any>();
    useEffect(() => {
        if (id) {
            dispatch(validate());
            dispatch(getClassDetailsAction(id));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const classDetails = useSelector<{ classDetails: ClassDetails | undefined }>(
        (state) => state.classDetails
    ) as ClassDetails | undefined;

    const [graphData, setGraphData] = useState<GraphsProps>(initGraphProps);
    // Set initial values for the slider (as timestamps)
    const [dateRange, setDateRange] = useState<DateRange>({
        ...initDateRange,
    });

    const [analyticsTab, setAnalyticsTab] = useState<TabType>("Class Total Time");


    const [state, setState] = useState({
        id: "",
        loading: false,
        currentTab: "DETAILS",
        openModal: false,
        openDeleteModal: false,
        selectedDeleteAssignment: {
            ...AssignmentInit,
        },
        selectedEditAssignment: {},
        modalBody: "",
        modalHeader: "",
        openAssignmentModal: false,
        isEditAssignment: false,
        openPeerReview: false,
        peerReviewAssignmentId: "",
        isEditClass: false,
    });
    return (
        <Main>
            {classDetails && (<>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={state.currentTab}
                            onChange={(event: any, newValue: any) => {
                                setState({
                                    ...state,
                                    currentTab: newValue,
                                });
                            }}
                            aria-label="Class details tabs"
                            centered
                        >

                            {Object.values(tabs).map((tab) => (
                                <Tab label={tab} value={tab} disabled={state.isEditClass} key={tab} />
                            ))}


                        </Tabs>
                    </Box> </Box>

                <Grid container spacing={2} my={2}>
                    <Grid item xs={12}>
                        {state.currentTab === tabs.DETAILS && (
                            <Details
                                className={classDetails.className}
                                subjectArea={classDetails.subjectArea}
                                studentLevel={classDetails.studentLevel}
                                startDate={classDetails.startDate}
                                endDate={classDetails.endDate}
                                classTimeZone={classDetails.classTimeZone}
                            />
                        )}
                        {state.currentTab === tabs.STUDENTS && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <StudentsList
                                        students={classDetails.students}
                                        assignments={classDetails.assignments}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {state.currentTab === tabs.ANALYTICS && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ClassDashboard
                                        data={graphData}
                                        setData={setGraphData}
                                        dateRange={dateRange}
                                        setDateRange={setDateRange}
                                        currentTab={analyticsTab}
                                        setCurrentTab={setAnalyticsTab}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {state.currentTab === tabs.ASSIGNMENTS && (
                            <Assignments
                                assignments={classDetails.assignments}
                                students={classDetails.students}

                                classTimeZone={classDetails.classTimeZone}
                            />
                        )}
                    </Grid>
                </Grid>
            </>)}

        </Main>
    );

};

export default TAClassDetails;