import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import Heatmap from "./Graphs/Heatmap";
import CloseIcon from "@mui/icons-material/Close";
import {
  GraphsProps,
  initGraphProps,
  assignmentTabs,
  TabType,
  tooltipsText,
} from "./models";
import {
  getassignmentHeatMapLogs,
  getassignmentLineGraphLogs,
  getassignmentTimeSeriesLogs,
} from "../../../../../services/classes.service";
import LineGraph from "./Graphs/LineGraph";
import TimeSeries from "./Graphs/TimeSeries";
import { useSelector } from "react-redux";
import { getTimeSeriesTotal } from "./services";

interface AnalyticsProps {
  assignmentId: string;
  handleClose: () => void;
}
const Analytics: React.FC<AnalyticsProps> = ({ assignmentId, handleClose }) => {
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState<TabType>("Heatmap");
  const [data, setData] = useState<GraphsProps>(initGraphProps);

  const assignments = useSelector((state: any) =>
    state.classDetails.assignments.filter(
      (item: any) => item.id === assignmentId
    )
  );
  const handleLocalClose = () => {
    setData(initGraphProps);
    setCurrentTab("Heatmap");
    setTimeout(handleClose,1);
  };
  const abortController = new AbortController(); // Create AbortController
  const signal = abortController.signal;

  const isDisabled = (tab: TabType): boolean => {
    switch (tab) {
      case "Heatmap":
        return data.HeatmapProps === null ? true : false;
      case "Hourly Activity":
        return data.lineGraphProps === null ? true : false;
      case "Words Changed":
        return data.TimeSeriesProps === null ? true : false;
      default:
        return false;
    }
  };

  useEffect(() => {
    const getHeadmapData = async () => {
      try {
        const heatMapLogs = await getassignmentHeatMapLogs(
          assignmentId,
          signal
        );
        return { HeatmapProps: JSON.parse(heatMapLogs.data) };
      } catch {}
    };

    const getLineGraphData = async () => {
      try {
        const lineGraphLogs = await getassignmentLineGraphLogs(assignmentId,signal);
        return { lineGraphProps: JSON.parse(lineGraphLogs.data) };
      } catch {}
    };
    const getTimeSeriesData = async () => {
      try {
        const timeSeriesLogs = await getassignmentTimeSeriesLogs(assignmentId,signal);
        return {
          TimeSeriesProps: [
            { id: "Words Changed", data: JSON.parse(timeSeriesLogs.data) },
          ],
        };
      } catch {}
    };

    const methods = [getHeadmapData, getLineGraphData, getTimeSeriesData];


    const fetchData = async () => {
      setData(initGraphProps);
      if (assignmentId && assignmentId !== null && assignmentId !== "") {
        for (const method of methods) {
          const result = await method();
          setData((prev) => ({ ...prev, ...result }));

          // Merge new data with the existing state
        }
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId]);

  return (
    <Dialog
      open={assignmentId !== ""}
      onClose={handleLocalClose}
      fullWidth
      fullScreen
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleLocalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1, color: "black" }}
            variant="h6"
            component="div"
          >
            Assignment Analytics
            {assignments[0] && ` - ${assignments[0].title}`}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            minHeight: "75vh",
          }}
        >
          <Tabs
            orientation="vertical"
            sx={{ borderRight: 1, borderColor: "divider" }}
            variant="scrollable"
            value={currentTab}
            onChange={(event: React.SyntheticEvent, newValue: TabType) =>
              setCurrentTab(newValue)
            }
          >
            {assignmentTabs.map((tab: TabType, index: number) => (
              <Tooltip
                title={tooltipsText[tab]}
                key={tab}
                placement="right-end"
              >
                <Tab
                  key={`analytic_tab_${index}`}
                  id={`analytic_tab_${index}`}
                  aria-controls={`simple-tabpanel-${index}`}
                  label={
                                      <>
                                        {tab}{" "}
                                        {isDisabled(tab) && (
                                          <CircularProgress color="inherit" size={16} />
                                        )}
                                      </>
                                    }
                  value={tab}
                  sx={{
                    color:
                      currentTab === tab
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                  }}
                  disabled={isDisabled(tab)}
                />
              </Tooltip>
            ))}
          </Tabs>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                ml: 2,
                maxHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
              }}
            >
              {currentTab === "Heatmap" && <Heatmap data={data.HeatmapProps} />}
              {currentTab === "Hourly Activity" && (
                <LineGraph
                  data={
                    data.lineGraphProps === null
                      ? data.lineGraphProps
                      : getTimeSeriesTotal(data.lineGraphProps)
                  }
                />
              )}

              {currentTab === "Words Changed" && (
                <TimeSeries data={data.TimeSeriesProps} />
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Analytics;
