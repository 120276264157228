import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./types";

import * as AuthService from "../services/auth.service";

export const register =
  (firstName, lastName, username, email, phone, password) => (dispatch) => {
    return AuthService.register(
      firstName,
      lastName,
      username,
      email,
      phone,
      password
    ).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const validate = () => (dispatch) => {
  if (!AuthService.validateLogin()) {
    AuthService.logout();
    dispatch({
      type: LOGOUT,
    });
    window.location.replace(`/Login?redirect_url=${window.location.pathname}`);
  }

  if (!AuthService.validateExpiry()) {
    AuthService.logout();
    dispatch({
      type: LOGOUT,
    });
    window.location.replace("/Login");
  }
};


export const oauth2loginUpdate = (token) => async (dispatch) => {
  try {
    const response = await AuthService.updateOauth(token); // Async API call
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { user: response.data },
    });
    return Promise.resolve();// Resolve the Promise with the API response
  } catch (error) {
    return Promise.reject();
  }
};

