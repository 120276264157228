import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  timelineContentClasses,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  Box,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,

  Typography,
} from "@mui/material";
import * as dateService from "../../../../services/date.service";
import Container from "@mui/material/Container";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { showTextDiff } from "../../../../services/classes.service";
import ChangeLineGraph from "./ChangeGraph";
import { useSelector, useDispatch } from "react-redux";
import {
  DeadlineSubmission,
  SubmissionDetails,
} from "../../../../services/models/assignments.model";
import FastForwardIcon from "@mui/icons-material/FastForward";
import {
  setCurrent,
  incCurrent,
  decCurrent,
  setIsPlaying,
  reset,
  setToState,
} from "../../../../store/Rewind/actions";
import RewindSlider from "./Slider";
import Legend from "../../../../components/Legend";
import { initialState, Rewind } from "../../../../store/Rewind/model";
import { Log } from "../../../../store/ReadAssignment/model";
import { formatDateToCustomString } from "./services";
import MaterialUISwitch from "./CustomSwitch";

const WritingProgress: React.FC = () => {
  const dispatch = useDispatch();
  const submissionData: SubmissionDetails = useSelector(
    (state: any) => state.assignment.submissionData
  );
  const { current, isPlaying, sleepTime } = useSelector(
    (state: any) => state.rewind
  );

  const [content, setContent] = useState<string | null>(null);
  const [sectionId, setSectionId] = useState<number>(0);
  const [disableUp, setDisableUp] = useState<boolean>(false);
  const [disableDown, setDisableDown] = useState<boolean>(false);
  const [speed, setSpeed] = useState<number>(1);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [iterationStep, setIterationStep] = useState<number>(0);
  const [draftRewind, setDraftRewind] = useState<boolean>(false);
  const [lastState, setLastState] = useState<Rewind & { speed: number }>({
    ...initialState,
    speed,
  });

  const [logs, setLogs] = useState<Log[]>(submissionData.submissionLog.logs);
  const handleDraftChange = (e: any) =>
    dispatch(setCurrent(parseInt(e.target.value)));
  useEffect(() => {
    scrollToSection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  useEffect(() => {
    // if the current slider state reaches the end of logs array then stop playing
    if (current > logs.length) {
      dispatch(setIsPlaying(false));
      setCurrent(logs.length - 1);
    } else {
      const updateDocument = async () => {
        await loadDocument();
        setSectionId(0);
        scrollToSection();
      };
      if (current + iterationStep < logs.length) {
        updateDocument();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current,logs]);

  const draftRewindCaption = (index: number): string => {
    if (draftRewind) {
      const draftsubmissions = submissionData.deadlineSubmissions;
      if (index === 1) {
        return `Initial vs.  ${draftsubmissions[0].title}`;
      } else {
        return `${draftsubmissions[index - 2].title} vs. ${
          draftsubmissions[index - 1].title
        }`;
      }
    } else {
      return "";
    }
  };

  const getRewindCaption = (): string => {
    return draftRewindCaption(current);
  };
  const handleRewindMode = () => {
    if (draftRewind === false) {
      setLastState({ current, isPlaying: false, sleepTime, speed });
      setSpeed(1);
      const draftlods: Log[] = submissionData.deadlineSubmissions.map(
        (e: DeadlineSubmission): Log => ({
          id: e.docId,
          change: 1,
          timestamp: formatDateToCustomString(new Date(e.date).toString()),
        })
      );
      setLogs([logs[0], ...draftlods]);
      dispatch(reset());
    } else {
      const { current, isPlaying, sleepTime, speed } = lastState;
      setLogs(submissionData.submissionLog.logs);
      dispatch(setToState({ current, isPlaying, sleepTime }));
      setSpeed(speed);
    }
    setDraftRewind(!draftRewind);
  };

  useEffect(() => {
    console.log(logs);
  }, [logs]);

  const isDraftRewind = useCallback((): boolean => {
    if (submissionData.deadlineSubmissions.length >= 2) return true;
    return false;
  }, [submissionData]);

  useEffect(() => {
    const isLogsLengthNotEqToCurrentOrLess =
      current !== logs.length - 1 && current < logs.length - 1;

    // run the player only when the current state value is less than length of logs array
    if (isPlaying && isLogsLengthNotEqToCurrentOrLess) {
      const timeout = setTimeout(() => {
        handleNext();
      }, sleepTime);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, current]);

  const scrollToSection = () => {
    const section = document.getElementById(
      "section" + sectionId.toString()
    ) as HTMLElement;
    const nextsection = sectionId + 1;
    const nextElementExists = !!document.getElementById(
      "section" + nextsection.toString()
    );
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    setDisableUp(sectionId <= 0);
    setDisableDown(!nextElementExists);
  };

  const increaseSpeed = (multiplier: number) => {
    if (speed === 4) {
      setSpeed(1);
    } else {
      setSpeed(speed * multiplier);
    }
  };
  const handleNext = () => {
    dispatch(incCurrent(speed));
  };

  const handleDown = () => {
    setSectionId(sectionId + 1);
  };

  const handleUp = () => {
    setSectionId(sectionId - 1);
  };

  const handlePrev = () => {
    dispatch(decCurrent());
  };

  const handlePlay = (value: boolean) => {
    dispatch(setIsPlaying(value));
  };
  const handleReset = (value: boolean) => {
    dispatch(setCurrent(1));
    dispatch(setIsPlaying(value));
  };

  const loadDocument = async () => {
    if (speed === 2) {
      // increase the difference between old and new id by 2
      setIterationStep(1);
    } else if (speed === 4) {
      // increase the difference between old and new id by 4
      setIterationStep(3);
    }
    // else if (speed === 8) {
    //   iterationStep = 7;
    // }
    else {
      setIterationStep(0);
    }
    const oldId = logs[current - 1].id;
    const newId = logs[current + iterationStep].id;

    const showDiff = await showTextDiff(oldId, newId);
    await setContent(showDiff.data.response);
  };
  const isLogsLengthNotEqToCurrentOrLess =
    current !== logs.length - 1 && current < logs.length - 1;

  return (
    <Container maxWidth={false}>
      {submissionData && (
        <Grid container sx={{ height: "100vh" }}>
          <Grid item xs={12} md={12} lg={8} xl={8}>
            {!draftRewind && (
              <>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <ChangeLineGraph />
                </Grid>
                <RewindSlider />
              </>
            )}
            <Container
              maxWidth={false}
              sx={{
                contain: "layout",
                height: "85vh",
                overflow: "auto",
                display: "flex",
                alignItems: "flex-start",
                minHeight: "85vh",
                border: "2px",
              }}
            >
              <div
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: content || "" }}
                ref={sectionRef}
              />
            </Container>
          </Grid>
          <Grid item xs={12} md={12} lg={4} xl={4}>
            <Box
              sx={{
                "& > :not(style)": { m: 1 },
                position: "sticky",
                top: "3.3rem",
              }}
            >
              <Fab
                color="primary"
                aria-label="add"
                onClick={handlePrev}
                disabled={current <= 1}
              >
                <ChevronLeftIcon />
              </Fab>
              <Fab
                aria-label="like"
                color="secondary"
                onClick={() =>
                  current >= logs.length - 1
                    ? handleReset(!isPlaying)
                    : handlePlay(!isPlaying)
                }
              >
                {current >= logs.length - 1 ? (
                  <ReplayOutlinedIcon />
                ) : isPlaying ? (
                  <PauseOutlinedIcon />
                ) : (
                  <PlayArrowOutlinedIcon />
                )}
              </Fab>
              <Fab
                color="primary"
                variant="extended"
                onClick={() => increaseSpeed(2)}
                disabled={draftRewind}
              >
                <FastForwardIcon />
                {speed}
              </Fab>
              <Fab
                color="primary"
                aria-label="edit"
                onClick={() => dispatch(incCurrent(1))}
                disabled={current >= logs.length - 1}
              >
                <ChevronRightOutlinedIcon />
              </Fab>
              <Fab color="primary" onClick={handleUp} disabled={disableUp}>
                <ExpandLessOutlinedIcon />
              </Fab>
              <Fab
                color="primary"
                aria-label="down"
                onClick={handleDown}
                disabled={disableDown}
              >
                <ExpandMoreOutlinedIcon />
              </Fab>
              <Timeline
                position="left"
                sx={{
                  [`& .${timelineContentClasses.root}`]: {
                    flex: 0.7,
                  },
                }}
              >
                <TimelineItem>
                  <TimelineOppositeContent
                    color="text.secondary"
                    // sx={{ flex: 0.4 }}
                  >
                    Start
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {isLogsLengthNotEqToCurrentOrLess
                      ? dateService.toTimeZone(logs[current - 1].timestamp)
                      : dateService.toTimeZone(logs[logs.length - 2].timestamp)}
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    color="text.secondary"
                    // sx={{ flex: 0.4 }}
                  >
                    Time elapsed
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {isLogsLengthNotEqToCurrentOrLess
                      ? dateService.dateDiffInSecs(
                          logs[current - 1].timestamp,
                          logs[current].timestamp
                        )
                      : dateService.dateDiffInSecs(
                          logs[logs.length - 2].timestamp,
                          logs[logs.length - 1].timestamp
                        )}
                  </TimelineContent>
                </TimelineItem>
                {draftRewind && (
                  <TimelineItem>
                    <TimelineOppositeContent
                      color="text.secondary"
                      // sx={{ flex: 0.4 }}
                    >
                      Drafts
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {" "}
                      <strong>{getRewindCaption()}</strong>
                    </TimelineContent>
                  </TimelineItem>
                )}
                <TimelineItem>
                  <TimelineOppositeContent
                    color="text.secondary"
                    // sx={{ flex: 0.4 }}
                  >
                    End
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    {isLogsLengthNotEqToCurrentOrLess
                      ? dateService.toTimeZone(logs[current].timestamp)
                      : dateService.toTimeZone(logs[logs.length - 1].timestamp)}
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
              {isDraftRewind() && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    ml: "3.3rem!important",
                  }}
                >
                  <Typography>Rewind Mode</Typography>
                  <MaterialUISwitch
                    checked={draftRewind}
                    onChange={handleRewindMode}
                  />
                  <Typography>Draft Comparison Mode</Typography>
                </Stack>
              )}
              <>
                <Legend
                  items={[
                    {
                      text: "New text",
                      color: "#008001",
                    },
                    {
                      text: "Edited text",
                      color: "#ffa500",
                    },
                    {
                      text: "Deleted text",
                      color: "#ff0000",
                    },
                  ]}
                />
              </>
              {draftRewind && (
                <FormControl sx={{ml:"3.3rem!important"}}>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Comparisons:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={current}
                  >
                    {submissionData.deadlineSubmissions.map(
                      (c: any, index: number) => (
                        <FormControlLabel
                          key={index}
                          value={index + 1}
                          control={<Radio />}
                          label={draftRewindCaption(index + 1)}
                          onChange={handleDraftChange}
                        />
                      )
                    )}
                  </RadioGroup>
                </FormControl>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default WritingProgress;
