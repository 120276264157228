import React from "react";
import {
  Container,
  Box,
  Paper,
  Typography,
  Avatar,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Input,
  Tooltip,
  Autocomplete,
  Button,
  Backdrop,
  CircularProgress,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import Main from "../../../elements/public/main";
import { generateEnrollKey } from "../../../services/class.service";
import authHeader from "../../../services/auth-header";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { connect } from "react-redux";
import Axios from "axios";
import { setMessage } from "../../../actions/message";
import { validate } from "../../../actions/auth";
import { etcGmtTimezones } from "../../../services/models/timezones";

import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {
  courseGradeTypes,
  gradeType,
  initLetterGrades,
  LetterGradesType,
} from "./model";

dayjs.extend(utc);
dayjs.extend(timezone);

const currentTimezone = Intl.DateTimeFormat()
  .resolvedOptions()
  .timeZone.toString();

const startDate: Dayjs = dayjs().utc().startOf("day");

const minLastDate: Dayjs = dayjs().add(1, "day").startOf("day").utc();
// To register new class from Instructor end.

const timezonesProps = {
  options: etcGmtTimezones.map((option) => option),
  getOptionLabel: (option: string) => option,
};

class RegisterClass extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleGenerateEKey = this.handleGenerateEKey.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.onSelectarea = this.onSelectarea.bind(this);
    this.onSelectlevel = this.onSelectlevel.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.onSelectTimezone = this.onSelectTimezone.bind(this);
    this.state = {
      successful: false,
      loading: false,
      classname: "",
      enrollkey: "",
      subjectarea: "",
      studentlevel: "",
      areaList: null,
      levelList: null,
      classStartDate: startDate,
      minclassStartDate: startDate,
      classEndDate: minLastDate,
      minClassEndDate: minLastDate,
      classTimeZone: currentTimezone,
      gradeMethod: "Numeric",
      grades: initLetterGrades,
    };
  }

  componentDidMount() {
    this.props.dispatch(validate());

    const API_URL = process.env.REACT_APP_API_URL + "/api/classes/";

    Axios.post(
      API_URL + "getlist",
      {},
      {
        params: { type: "level" },
        headers: authHeader(),
      }
    ).then((response) => {
      this.setState({
        levelList: response.data,
      });
    });

    Axios.post(API_URL + "getlist", null, {
      params: { type: "area" },
      headers: authHeader(),
    }).then((response) => {
      this.setState({
        areaList: response.data,
      });
    });

    this.handleGenerateEKey();
  }
  // TODO add interface
  handleChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChangeGrades(value: LetterGradesType) {
    this.setState({
      grades: this.state.grades.map((g) =>
        g.value === value ? { ...g, selected: !g.selected } : g
      ),
    });
  }

  // TODO add interface
  handleDateChange(e: any) {
    var endDate = dayjs(e).add(1, "day").startOf("date");
    if (this.state.classEndDate > endDate) {
      endDate = this.state.classEndDate;
    }
    this.setState({
      classStartDate: e,
      minClassEndDate: endDate,
      classEndDate: endDate,
    });
  }
  handleEndDateChange(e: string) {
    this.setState({
      classEndDate: e,
    });
  }

  handleGenerateEKey() {
    const key = generateEnrollKey(5);
    const { user: currentUser } = this.props;
    const prefix = currentUser.firstName
      .substring(0, 1)
      .concat(currentUser.lastName.substring(0, 1), "-");
    const ekey = prefix.concat(key);

    this.setState({
      enrollkey: ekey,
    });
  }

  // TODO add interface
  handleRegister(e: any) {
    e.preventDefault();
    const API_URL = process.env.REACT_APP_API_URL + "/api/classes/";
    const {
      classname,
      enrollkey,
      subjectarea,
      studentlevel,
      classStartDate,
      classEndDate,
      classTimeZone,
      gradeMethod,
      grades,
    } = this.state;
    this.setState({
      loading: true,
    });

    Axios.post(
      API_URL + "registerclass",
      {
        classname,
        enrollkey,
        classStartDate: classStartDate,
        classEndDate: classEndDate,
        subjectarea,
        studentlevel,
        classTimeZone,
        gradeMethod,
        grades: gradeMethod === "Letter" ? grades : initLetterGrades,
      },
      { headers: authHeader() }
    ).then(
      (res) => {
        this.setState({
          loading: false,
          successful: true,
        });
        setTimeout(() => {
          window.location.replace("classes");
        }, 5000);
      },
      (error) => {
        const messages =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState({
          loading: false,
        });

        this.props.dispatch(setMessage(messages));
      }
    );
  }

  // TODO add interface
  onSelectarea(e: any, value: any) {
    if (value) {
      this.setState({
        subjectarea: value.id,
      });
    } else {
      this.setState({
        subjectarea: null,
      });
    }
  }

  // TODO add interface
  onSelectlevel(e: any, value: any) {
    if (value) {
      this.setState({
        studentlevel: value.id,
      });
    } else {
      this.setState({
        subjectarea: null,
      });
    }
  }

  startedDate(value: string): Dayjs {
    return dayjs().tz(value).startOf("date");
  }

  endedDate(value: string): Dayjs {
    return dayjs().add(1, "day").tz(value).startOf("date");
  }

  onSelectTimezone(e: any, value: string | null) {
    if (value) {
      this.setState({
        classTimeZone: value,
        classStartDate: this.startedDate(value),
        minclassStartDate: this.startedDate(value),
        classEndDate: this.endedDate(value),
        minClassEndDate: this.endedDate(value),
      });
    } else {
      this.setState({
        classTimeZone: "default",
      });
    }
  }

  render() {
    const areaProps = {
      options: this.state.areaList,
      getOptionLabel: (option: any) => option.name, // TODO add interface
    };
    const levelProps = {
      options: this.state.levelList,
      getOptionLabel: (option: any) => option.name, // TODO add interface
    };

    const { classStartDate, classEndDate, minClassEndDate, minclassStartDate } =
      this.state;

    const { message } = this.props;

    return (
      <Main>
        <Container maxWidth="sm">
          <Paper sx={{ my: { xs: 1, md: 3 }, p: { xs: 2, md: 3 } }}>
            <Box
              sx={{
                my: 0,
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{ m: 1, bgcolor: "secondary.main", width: 56, height: 56 }}
              >
                <MenuBookOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {this.state.successful
                  ? "Class created successfully."
                  : "Create a new class"}
              </Typography>
            </Box>
            {!this.state.successful && (
              <Grid
                container
                spacing={3}
                component="form"
                onSubmit={this.handleRegister}
                // ref={(c) => {
                //   this.form = c;
                // }}
              >
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="classname"
                    name="classname"
                    label="Class Name"
                    fullWidth
                    variant="standard"
                    margin="normal"
                    onChange={this.handleChange}
                    value={this.state.classname}
                    sx={{ my: { xs: 1, md: 2 } }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="enrollkey">Enrollment Key*</InputLabel>
                    <Input
                      id="enrollkey"
                      name="enrollkey"
                      required
                      onChange={this.handleChange}
                      value={this.state.enrollkey}
                      disabled
                      endAdornment={
                        <InputAdornment position="end">
                          <Tooltip title="Autogenerate">
                            <IconButton
                              aria-label="Generate class enrollment key"
                              onClick={this.handleGenerateEKey}
                            >
                              <RestartAltIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      }
                      sx={{ my: { xs: 1, md: 2 } }}
                    />
                  </FormControl>
                </Grid>
                {this.state.areaList && (
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      {...areaProps}
                      id="subjectarea"
                      // name="subjectarea"
                      autoSelect
                      onChange={this.onSelectarea}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Subject Area"
                          variant="standard"
                          required
                        />
                      )}
                      // sx={{ my: { xs: 1, md: 2 } }}
                    />
                  </Grid>
                )}

                {this.state.levelList && (
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      {...levelProps}
                      id="studentlevel"
                      // name="studentlevel"
                      autoSelect
                      onChange={this.onSelectlevel}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Student Level"
                          variant="standard"
                          required
                        />
                      )}
                      sx={{ my: { xs: 1, md: 2 } }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    onChange={(event: any, newValue: string | null) =>
                      this.onSelectTimezone(event, newValue)
                    }
                    value={this.state.classTimeZone}
                    {...timezonesProps}
                    id="classTimeZone"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Class Time Zone"
                        variant="standard"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      value={classStartDate}
                      minDate={minclassStartDate}
                      onChange={(newValue) => {
                        this.handleDateChange(newValue);
                      }}
                      sx={{ width: "100%" }}
                      timezone={this.state.classTimeZone}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      value={classEndDate}
                      minDate={minClassEndDate}
                      onChange={(newValue) => {
                        this.handleEndDateChange(newValue);
                      }}
                      sx={{ width: "100%" }}
                      timezone={this.state.classTimeZone}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ToggleButtonGroup
                    value={this.state.gradeMethod}
                    exclusive
                    onChange={this.handleChange}
                    aria-label="text alignment"
                  >
                    {courseGradeTypes.map((c: gradeType) => (
                      <ToggleButton value={c} name="gradeMethod">
                        {c}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                {this.state.gradeMethod === "Letter" && (
                  <Grid item xs={12} sm={12}>
                    <FormGroup row>
                      {this.state.grades.map((c) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={c.value.length === 1}
                              checked={c.selected}
                              onChange={() => this.handleChangeGrades(c.value)}
                            />
                          }
                          label={c.value}
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                )}
                {message && (
                  <Grid item xs={12}>
                    <Alert severity="error">{message}</Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    <span>Create</span>
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Container>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Main>
    );
  }
}

function mapStateToProps(state: any) {
  const { user } = state.auth;
  const { message } = state.message;
  return {
    user,
    message,
  };
}

export default connect(mapStateToProps)(RegisterClass);
