import React from "react";
import { Box, Grid, Paper,  } from "@mui/material";

interface DetailsProps {
  className: string;
  subjectArea: string;
  studentLevel: string;
  startDate: string;
  endDate: string;
  classTimeZone: String;
}
function Details(props: DetailsProps) {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} lg={8}>
        <Paper elevation={24}>
          <Box sx={{ p: 2, pb: 0, border: "1px solid grey" }}>
            <Grid container spacing={2}>
              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                Class Name
              </Grid>
              <Grid item xs={8}>
                {props.className}
              </Grid>

              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                Subject Area
              </Grid>
              <Grid item xs={8}>
                {props.subjectArea}
              </Grid>

              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                Student Level
              </Grid>
              <Grid item xs={8}>
                {props.studentLevel}
              </Grid>

              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                Start Date
              </Grid>
              <Grid item xs={8}>
                {props.startDate.substring(0, 10)}
              </Grid>
              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                End Date
              </Grid>
              <Grid item xs={8}>
                {props.endDate.substring(0, 10)}
              </Grid>
              <Grid item xs={4} sx={{ bgcolor: "text.hint" }}>
                Class Timezone
              </Grid>
              <Grid item xs={8}>
                {props.classTimeZone}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
export default Details;
