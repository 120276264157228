import React, { useEffect, useState } from "react";
import {
  Card,
  Box,
  IconButton,
  Typography,
  CardContent,
  CardActions,
  Button,
  TextField,
  Stack,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import SendIcon from "@mui/icons-material/Send";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import LaptopOutlinedIcon from "@mui/icons-material/LaptopOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { gradeStatus } from "../constants";
import { apiIds } from "../../../../store/ReadAssignment/constants";
import { isBefore, parseISO } from "date-fns";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CommentPanel from "./CommentPanel";
import SubmissionPanel from "./SubmissionPanel";
import GradeBook from "./GradeBook";
import { isExcepted, showCommenting } from "./utils";
import {
  SUBMIT_TOOLTIP,
  ENDCOMMENT_TOOLTIP,
  EVAL_TOOLTIP,
  GB_TOOLTIP,
  REOPEN_EXCEPTION_TOOLTIP,
} from "./TooltipText";
import ReopenException from "./ExceptionPanel/ReopenException";
import { useSelector } from "react-redux";
import { DeadlineSubmission } from "../../../../store/ReadAssignment/model";
import { gradeType, LetterGradesType } from "../../CreateCourse/model";

import InsightsIcon from "@mui/icons-material/Insights";
import { AnalyticsOpenProps, initAnalyticsOpenState } from "../Analytics/model";
import AssignmentAnalyticsDashboard from "../Analytics/Assignment/Dashboard";
import StudentAnalyticsDashboard from "../Analytics/Student/Dashboard";

interface GradeSubmissionProps {
  assignment: any;
  handleMove: (index: number) => void;
  handleSetToRevise: () => void;
  handleSetGrade: () => void;
  handleSetAltGrade: () => void;
  handleSetToFinal: () => void;
  onGradeChange: (event: any) => void;
  onSetReOpen: () => void;
  loadingState: any;
  grade: string;
  branch: string;
  alternateGrade: LetterGradesType;
}
function GradeSubmission(props: GradeSubmissionProps) {
  function disableEvaluateButton() {
    return isBefore(
      parseISO(props.assignment?.lastModification),
      parseISO(props.assignment?.scoresHistory[0]?.timestamp) ||
        props.assignment?.scoresHistory[0]?.graded
    );
  }

  const gradeMethod: gradeType = useSelector(
    (state: any) => state.assignment.submissionData.gradeMethod
  );
  const gradeOptions: LetterGradesType[] = useSelector(
    (state: any) => state.assignment.submissionData.gradeOptions
  );

  const deadlineSubmissions: DeadlineSubmission[] | undefined = useSelector<
    any,
    DeadlineSubmission[] | undefined
  >((state) => state.assignment.submissionData?.deadlineSubmissions);

  const submission_status: string | undefined = useSelector<
    any,
    string | undefined
  >((state) => state.assignment.submissionData?.status);

  const [commentOpen, setCommentOpen] = useState<boolean>(false);
  const handleCloseComment = () => {
    setCommentOpen(false);
  };
  const handleOpenComment = () => {
    setCommentOpen(true);
  };

  const [submissionOpen, setSubmissionOpen] = useState<boolean>(false);
  const handleCloseSubmission = () => {
    setSubmissionOpen(false);
  };
  const handleOpenSubmission = () => {
    setSubmissionOpen(true);
  };

  const [gradeBookOpen, setGradeBookOpen] = useState<boolean>(false);
  const handleCloseGradeBook = () => {
    setGradeBookOpen(false);
  };
  const handleOpenGradeBook = () => {
    setGradeBookOpen(true);
  };

  const [expPanelOpen, setExpPanelOpen] = useState<boolean>(false);
  const handleCloseExption = () => {
    setExpPanelOpen(false);
  };

  const handleOpenExption = () => {
    setExpPanelOpen(true);
  };

  const [analyticState, setAnalyticState] = useState<AnalyticsOpenProps>(
    initAnalyticsOpenState
  );

  return (
    <Card>
      <Box sx={{ display: "flex", alignItems: "center", px: 1, pb: 1 }}>
        <IconButton
          aria-label="previous"
          sx={{ mr: "auto" }}
          onClick={async () => await props.handleMove(-1)}
          disabled={props.assignment.currentStudentIndex === 0}
        >
          <ChevronLeftOutlinedIcon />
        </IconButton>

        <IconButton aria-label="Indicator">
          <Typography variant="h2">
            {props.assignment.currentStudentIndex + 1} /
            {props.assignment.numberOfStudents}
          </Typography>
        </IconButton>

        <IconButton
          aria-label="next"
          sx={{ ml: "auto" }}
          onClick={async () => await props.handleMove(1)}
          disabled={
            props.assignment.numberOfStudents ===
            props.assignment.currentStudentIndex + 1
          }
        >
          <ChevronRightOutlinedIcon />
        </IconButton>
      </Box>

      <CardContent sx={{ pb: 0 }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.assignment.className}
        </Typography>
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          variant="subtitle2"
        >
          {props.assignment.assignmentTitle}
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() =>
              setAnalyticState({ ...analyticState, assignment: true })
            }
          >
            <InsightsIcon fontSize="inherit" />
          </IconButton>
        </Typography>

        <Typography variant="h6" component="div">
          {props.assignment.studentName}

          <IconButton size="small">
            <InsightsIcon
              fontSize="inherit"
              onClick={() =>
                setAnalyticState({ ...analyticState, student: true })
              }
            />
          </IconButton>
        </Typography>
        <Typography variant="subtitle2" component="div">
          <b>Status:</b> {props.assignment.status}
        </Typography>
        <Typography variant="subtitle2" component="div">
          <b>Version:</b> {props.branch}
        </Typography>
        {gradeStatus.Grade.includes(props.assignment.status) && (
          <>
            {gradeMethod === "Numeric" && (
              <>
                <br />
                <br />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TextField
                    type="number"
                    fullWidth
                    required
                    id="grade"
                    label="grade"
                    name="grade"
                    disabled={props.loadingState.loadingIds.includes(
                      apiIds.gradeSubmission
                    )}
                    placeholder="--"
                    value={parseInt(props.grade) !== -1 && props.grade}
                    onChange={props.onGradeChange}
                    onBlur={() => props.handleSetGrade()}
                    variant="standard"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                  />
                </Box>
              </>
            )}
            {gradeMethod === "Letter" && (
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                fullWidth
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Grade
                </InputLabel>
                <Select
                  value={props.alternateGrade}
                  onChange={props.onGradeChange}
                  onBlur={() => props.handleSetAltGrade()}
                  label="Grade"
                  name="alternateGrade"
                >
                  <MenuItem value={""}>
                    <em>None</em>
                  </MenuItem>
                  {gradeOptions.map((c) => (
                    <MenuItem value={c}>{c}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}
      </CardContent>
      <CardActions>
        <Stack
          spacing={{ xs: 1, sm: 1 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          {props.assignment.grade === -1 && (
            <Tooltip title={SUBMIT_TOOLTIP} placement="right">
              <Button
                disabled={props.loadingState.loadingIds.includes(
                  apiIds.finalizeSubmission
                )}
                variant="outlined"
                endIcon={
                  <SendIcon
                    sx={{
                      color: "black",
                    }}
                  />
                }
                fullWidth
                onClick={handleOpenSubmission}
              >
                Submit
              </Button>
            </Tooltip>
          )}

          {showCommenting(props.assignment.deadlineSubmissions) && (
            <Tooltip title={ENDCOMMENT_TOOLTIP} placement="right">
              <Button
                disabled={props.loadingState.loadingIds.includes(
                  apiIds.finalizeSubmission
                )}
                variant="outlined"
                endIcon={
                  <RateReviewIcon
                    sx={{
                      color: "black",
                    }}
                  />
                }
                fullWidth
                onClick={handleOpenComment}
              >
                End Commenting
              </Button>
            </Tooltip>
          )}
          {props.assignment.deadlineSubmissions.length > 0 && (
            <Tooltip title={GB_TOOLTIP} placement="right">
              <Button
                fullWidth
                variant="outlined"
                endIcon={
                  <StickyNote2Icon
                    sx={{
                      color: "black",
                    }}
                  />
                }
                onClick={handleOpenGradeBook}
              >
                Grade Book
              </Button>
            </Tooltip>
          )}

          <Tooltip title={EVAL_TOOLTIP} placement="right">
            <Button
              fullWidth
              disabled={
                props.loadingState.loadingIds.includes(
                  apiIds.evaluateSubmission
                ) || disableEvaluateButton()
              }
              variant="contained"
              endIcon={<LaptopOutlinedIcon />}
              onClick={() => {
                props.handleSetToRevise();
              }}
            >
              Evaluate
            </Button>
          </Tooltip>
          <Tooltip title={REOPEN_EXCEPTION_TOOLTIP} placement="right">
            <Button
              fullWidth
              variant="contained"
              endIcon={
                <LockResetOutlinedIcon
                  color={
                    isExcepted(deadlineSubmissions, submission_status)
                      ? "warning"
                      : "inherit"
                  }
                />
              }
              onClick={handleOpenExption}
            >
              Reopen/exception
            </Button>
          </Tooltip>
        </Stack>
      </CardActions>
      <CommentPanel open={commentOpen} handleClose={handleCloseComment} />
      <SubmissionPanel
        open={submissionOpen}
        handleClose={handleCloseSubmission}
      />
      <GradeBook handleClose={handleCloseGradeBook} open={gradeBookOpen} />
      <ReopenException open={expPanelOpen} handleClose={handleCloseExption} />
      <AssignmentAnalyticsDashboard
        open={analyticState.assignment}
        handleClose={() =>
          setAnalyticState({ ...analyticState, assignment: false })
        }
      />
      <StudentAnalyticsDashboard
        open={analyticState.student}
        handleClose={() =>
          setAnalyticState({ ...analyticState, student: false })
        }
      />
    </Card>
  );
}
export default GradeSubmission;
