import React, { useEffect, useState } from "react";
import {
  AnalyticsDialogProps,
  initStudentAnalytics,
  StudentAnalyticsProps,
  studentTabs,
  TabType,
} from "../model";

import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import {
  analyzeSubmissionByStudent,
  getStudentEssayMapByClass,
  getStudentHeatMapLogs,
  getStudentLineGraphLogs,
  getStudentTimeSeriesLogs,
} from "../../../../../services/classes.service";
import TimeSeries from "../../../ClassDetails/Assignments/Analytics/Graphs/TimeSeries";
import {
  getAvergeWord,
  getTimeSeriesTotal,
} from "../../../ClassDetails/Assignments/Analytics/services";
import LineGraph from "../../../ClassDetails/Assignments/Analytics/Graphs/LineGraph";
import Heatmap from "../../../ClassDetails/Assignments/Analytics/Graphs/Heatmap";
import WordChangeGraph from "../../../ClassDetails/Assignments/Analytics/Graphs/WordChangeGraph";
import Histogram from "../../../ClassDetails/Assignments/Analytics/Graphs/Histogram";

const style: React.CSSProperties = {
  maxWidth: "95vw",
  maxHeight: "80vh",
};

const StudentAnalyticsDashboard: React.FC<AnalyticsDialogProps> = ({
  open,
  handleClose,
}) => {
  const [state, setState] =
    useState<StudentAnalyticsProps>(initStudentAnalytics);

  const { currentTab } = state;
  const { classId, studentId, studentName, assignmentTitle } = useSelector(
    (state: any) => state.assignment.submissionData
  );

  useEffect(() => {
    const getAssignmentActivity = async () => {
      const response = await getStudentTimeSeriesLogs(classId, studentId);
      return {
        assignmentActivityData: response.data.filter((c) => c.data.length > 0),
      };
    };

    const getHourlyActivity = async () => {
      const lineGraphLogs = await getStudentLineGraphLogs(classId, studentId);
      return {
        hourlyActivityData: JSON.parse(lineGraphLogs.data),
      };
    };

    const getHeadmapData = async () => {
      const heatMapLogs = await getStudentHeatMapLogs(classId, studentId);
      return { heatmapData: JSON.parse(heatMapLogs.data) };
    };

    const getEssayMapData = async () => {
      const response = await getStudentEssayMapByClass(classId, studentId);

      return { essayMapData: response.data };
    };

    const getWordChange = async () => {
      const response = await analyzeSubmissionByStudent(classId, studentId);
      return { wordChangeData: response.data };
    };
    const methods = [
      getAssignmentActivity,
      getHourlyActivity,
      getHeadmapData,
      getEssayMapData,
      getWordChange,
    ];
    const fetchData = async () => {
      if (classId && studentId) {
        for (const method of methods) {
          try {
            const result = await method();
            setState((prev) => ({ ...prev, ...result }));
          } catch {}

          // Merge new data with the existing state
        }
      }
    };
    fetchData();
  }, [classId, studentId]);
  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Student Analytics - {studentName}, {assignmentTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {" "}
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            minHeight: "75vh",
          }}
        >
          <Tabs
            orientation="vertical"
            sx={{ borderRight: 1, borderColor: "divider" }}
            variant="scrollable"
            value={currentTab}
            onChange={(event: React.SyntheticEvent, newValue: TabType) =>
              setState({ ...state, currentTab: newValue })
            }
          >
            {studentTabs.map((tab: TabType, index: number) => (
              <Tab
                key={`analytic_tab_${index}`}
                id={`analytic_tab_${index}`}
                aria-controls={`simple-tabpanel-${index}`}
                label={tab}
                value={tab}
              />
            ))}
          </Tabs>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                ml: 2,
                maxHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
              }}
            >
              {currentTab === "Assignment Activity" && (
                <TimeSeries data={state.assignmentActivityData} />
              )}
              {currentTab === "Hourly Activity" && (
                <LineGraph
                  data={
                    state.hourlyActivityData === null
                      ? state.hourlyActivityData
                      : getTimeSeriesTotal(state.hourlyActivityData)
                  }
                />
              )}
              {currentTab === "Heatmap" && <Heatmap data={state.heatmapData} />}
              {currentTab === "EssayMap" && state.essayMapData && (
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: state.essayMapData }}
                    style={style}
                  />
                  <>Legend</>
                </Stack>
              )}
              {currentTab === "Word Change" && state.wordChangeData && (
                <>
                  <WordChangeGraph data={state.wordChangeData} maxY={200} />
                </>
              )}
              {currentTab === "WPM Averages" && state.wordChangeData && (
                <>
                  <Histogram data={getAvergeWord(state.wordChangeData)} />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StudentAnalyticsDashboard;
