export interface Rewind{
    current:number;
    isPlaying:boolean;
    sleepTime:number;
}

export const initialState:Rewind = {
    current:0,
    isPlaying:false,
    sleepTime:1000,
}