import React from 'react';
import { BarChartData } from '../models';
import { ResponsiveBar } from '@nivo/bar';
import { formatTime, secondToHDTime } from '../services';

interface StudentBarChartProps {
    data: BarChartData[], 
    keys:string[],
}

const StudentBarChart: React.FC<StudentBarChartProps> = ({ data,keys }) => {

    return (
        <ResponsiveBar
            data={data}
            keys={keys}
            indexBy="student"
            margin={{ top: 50, right: 130, bottom: 50, left: 140 }}
            padding={0.3}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: "category10" }}
            
            axisTop={null}
            axisRight={null}
            axisBottom={{
                format: formatTime,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Time Consumed",
                legendPosition: "middle",
                legendOffset: -80,
                truncateTickAt: 0,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: -40,
                truncateTickAt: 0
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            motionConfig="molasses"
            role="application"
            ariaLabel="Nivo bar chart demo"

            tooltip={({ id, value, color }) => (
                <div
                    style={{
                        padding: 12,
                        color,
                        background: "white",
                    }}
                >
                    <strong>
                        {id}: {secondToHDTime(value)}
                    </strong>
                </div>
            )}
            theme={{
                tooltip: {
                    container: {
                        background: "#333",
                    },
                },
            }}
            label={d => `${secondToHDTime(d.value ? d.value : 0)}`}
        />
    );
};

export default StudentBarChart;