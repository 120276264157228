import React, { useEffect, useState } from "react";
import { DataProps, initComponentData } from "./model";

import { parseISO, format } from "date-fns";
import { useDispatch } from "react-redux";
import { validate } from "../../../actions/auth";
import { useParams } from "react-router-dom";
import { getStudentClass } from "../../../services/classes.service";
import Main from "../../../elements/public/main";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { utcToZonedTime } from "date-fns-tz";
import { handlePeerReviewDialog, submitPeerReview } from "./services";
import PeerReviewDialog from "./PeerReview/PeerReviewDialog";
import { Review } from "../../../services/models/classes.model";
import { setSnackbarMessage } from "../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../store/UIActions/Snackbar/model";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Dispatch the validate action when the component mounts
    // @ts-ignore
    dispatch(validate());
  }, [dispatch]);
  const { classid } = useParams();
  const [state, setState] = useState<DataProps>({
    ...initComponentData,
    id: classid ? classid : "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  useEffect(() => {
    const load = async () => {
      try {
        if (classid) {
          const courseData = await getStudentClass(classid);
          if (courseData.status === 200) {
            setState({
              ...state,
              myclass: courseData.data,
              id: classid ? classid : "",
            });
          }
        }
      } catch {}
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classid]);

  const handleSubmitPeerReview = async (id: string) => {
    try {
      const response = await submitPeerReview(id);
      if (response.status === 200 && response.data) {
        // update
        const reviews = state.myclass?.reviews || [];

        const updatedPR: Review[] = reviews.map((c) => {
          // Create a new object with updated status if ids match
          return c.id === id ? { ...c, status: "SUBMITTED" } : { ...c };
        });

        var updatedClass = state.myclass;
        if (updatedClass) {
          updatedClass = { ...updatedClass, reviews: updatedPR };

          // Use the functional form of setState to avoid stale state issues

          setState((prevState) => ({
            ...prevState,
            myclass: updatedClass,
            review: null,
          }));
          dispatch(
            setSnackbarMessage({
              type: AlertTypes.SUCCESS,
              showSnackBar: true,
              message: `The review submitted successfully.`,
            })
          );

          return;
        }
        throw new Error("");
      }
    } catch {
      dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `An error occured during submission.`,
        })
      );
    }
  };

  const toTimeZone = (date: string) => {
    const { timeZone } = state;
    return format(
      utcToZonedTime(parseISO(date), timeZone),
      "yyyy-MM-dd kk:mm a"
    );
  };

  const getWriteStatus = (assignmentindex: number): string => {
    const assignment = state.myclass?.assignments[assignmentindex];
    if (assignment?.status) {
      return assignment.isWriteable ? "Write" : "Read";
    }

    return "Start";
  };

  const openPeerReview = (id: string) => {
    handlePeerReviewDialog(id, state, setState);
  };

  const closePeerReview = () => {
    handlePeerReviewDialog("", state, setState);
  };

  const showDesc = (e: any) => {
    const assignment = state.myclass?.assignments.find(
      (item: any) => item.id === e // TODO add interface
    );
    if (assignment) {
      setState({
        ...state,
        modalBody: assignment?.description,
        modalHeader: assignment?.title,
        openModal: true,
      });
    }
  };

  const goToDetails = (e: string) => {
    window.location.replace("/assignment/" + e);
  };

  const handleModalClose = () => {
    setState({
      ...state,
      modalBody: "",
      modalHeader: "",
      openModal: false,
    });
  };

  const { myclass } = state;
  return (
    <Main>
      {myclass && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} sx={{ mt: 1 }}>
            <Paper>
              <Grid container spacing={2} m={0}>
                <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                  Class name
                </Grid>
                <Grid item xs={12} md={8}>
                  {myclass.className}
                </Grid>
                <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                  Instructor
                </Grid>
                <Grid item xs={12} md={8}>
                  {myclass.instructorName}
                </Grid>
                <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                  Subject Area
                </Grid>
                <Grid item xs={12} md={8}>
                  {myclass.subjectArea}
                </Grid>
                <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                  Student Level
                </Grid>
                <Grid item xs={12} md={8}>
                  {myclass.studentLevel}
                </Grid>
                <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                  Start Date
                </Grid>
                <Grid item xs={12} md={8}>
                  {toTimeZone(myclass.startDate.toString())}
                </Grid>
                <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                  End Date
                </Grid>
                <Grid item xs={12} md={8}>
                  {toTimeZone(myclass.endDate.toString())}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2} m={0}>
              <Grid item xs={12}>
                <Typography variant="h4" mb={1}>
                  {/* {" "} */}
                  Assignments List
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>

                        <TableCell align="left">Start Date</TableCell>
                        <TableCell align="left">Due Date</TableCell>
                        <TableCell>Grade</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* TODO add interface */}
                      {myclass.assignments.map((row: any, index: number) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="left">{row.title}</TableCell>

                          <TableCell align="left">
                            {toTimeZone(row.startDate)}
                          </TableCell>
                          <TableCell align="left">
                            {toTimeZone(row.endDate)}
                          </TableCell>
                          <TableCell align="left">
                            {myclass.gradeMethod==="Numeric" && (row.score !== -1 ? row.score : "-")}
                            {myclass.gradeMethod==="Letter" && row.alternateGrade }
                          </TableCell>
                          <TableCell align="right">
                            {/* <IconButton
                              onClick={(e) => showDesc(row.id)}
                              aria-label="expand row"
                              size="small"
                            >
                              <InfoOutlinedIcon />
                            </IconButton> */}

                            <ButtonGroup variant="contained">
                              <Button onClick={(e) => showDesc(row.id)}>
                                Description
                              </Button>
                              <Button onClick={() => goToDetails(row.id)}>
                                {getWriteStatus(index)}
                              </Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {myclass.reviews.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h4" mb={1}>
                      {/* {" "} */}
                      Peer Review List
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Assignment Title</TableCell>

                            <TableCell align="left">Draft Title</TableCell>
                            <TableCell align="left">Student</TableCell>
                            <TableCell>Submission Date</TableCell>
                            <TableCell>Deadline</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* TODO add interface */}
                          {myclass.reviews.map((row: any) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">
                                {row.assignmentTitle}
                              </TableCell>

                              <TableCell align="left">
                                {row.draftTitle}
                              </TableCell>
                              <TableCell align="left">
                                {row.studentName}
                              </TableCell>
                              <TableCell align="left">
                                {toTimeZone(row.submissionDate)}
                              </TableCell>
                              <TableCell align="left">
                                {toTimeZone(row.deadline)}
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  variant="contained"
                                  onClick={() => openPeerReview(row.id)}
                                >
                                  {row.status !== "SUBMITTED"
                                    ? "Review"
                                    : "Read"}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Divider sx={{ my: 2 }} />
      <Dialog
        onClose={handleModalClose}
        open={state.openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle>
          {state.modalHeader} <Divider />{" "}
        </DialogTitle>
        <Typography variant="body2" mx={4} mb={2}>
          {state.modalBody}
        </Typography>
      </Dialog>
      <PeerReviewDialog
        open={state.review !== null}
        handleClose={closePeerReview}
        review={state.review}
        handleSubmit={handleSubmitPeerReview}
      />
    </Main>
  );
};

export default Dashboard;
