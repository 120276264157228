import React, { useCallback, useEffect, useState } from "react";
import Main from "../../../elements/public/main";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { validate } from "../../../actions/auth";
import { StudentAssignmentData, initialState } from "./model";

import {
  Backdrop,
  Container,
  Grid,
  CircularProgress,
  Paper,

} from "@mui/material";

import Details from "./Details";
import { toTimeZoneSimple } from "../../../services/date.service";
import authToken from "../../../services/auth-token";
import FullScreen from "./Editor/FullScreen";
import { getStudentAssignment, newDocument, submission } from "./service";
import { TrackChangeMethods, apiIds } from "./utils";
import {
  setIsLoaded,
  setIsLoading,
} from "../../../store/UIActions/Loading/actions";
import { setSnackbarMessage } from "../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../store/UIActions/Snackbar/model";
import Analytics from "./Analytics/Analytics";
import { AnalyticsData, initAnalyticsData } from "./Analytics/model";

const StudentAssignmentDashboard: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState<StudentAssignmentData>(initialState);
  const [fileId, setFileId] = useState<string>("");
  const [submissionId, setSubmissionId] = useState<string>("");

  const [target, setTarget] = useState<any>("collabora-online-viewer");
  const [exception, setException] = useState<boolean>(false);
  const EDITOR_URL = process.env.REACT_APP_EDITOR_URL;

  const [autoSaveMode, setAutoSaveMode] = useState<boolean>(false);
  const [openFScollabora, setToFullscreen] = useState<boolean>(false);

  const [analyticsopen, setAnalytics] = useState<boolean>(false);
  const handleAnalyticsClose = () => setAnalytics(false);
  const handleAnalyticsOpen = () => setAnalytics(true);

  const apiList: string[] = useSelector<any, string[]>(
    (state) => state.loader.loadingIds
  );
  const showLoader = useCallback(
    () => apiList.includes(apiIds.SUBMIT),
    [apiList]
  );

  const TRConfig = async (type: any) => {
    const obj = {
      MessageId: "CallPythonScript",
      SendTime: Date.now(),
      ScriptFile: TrackChangeMethods.FILENAME,
      Function: type,
      Values: {},
    };
    window.frames[target].postMessage(JSON.stringify(obj), "*");

    return;
  };

  const [analyticsData, setAnalyticsData] =
    useState<AnalyticsData>(initAnalyticsData);

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (EDITOR_URL?.includes(event.origin)) {
          try {
            JSON.parse(event.data);
          } catch (e) {
            return;
          }
          if (
            JSON.parse(event.data).MessageId === "App_LoadingStatus" &&
            JSON.parse(event.data).Values.Status === "Frame_Ready"
          ) {
            const obj = { MessageId: "Host_PostmessageReady" };
            window.frames[target].postMessage(JSON.stringify(obj), "*");
          } else if (
            JSON.parse(event.data).MessageId === "App_LoadingStatus" &&
            JSON.parse(event.data).Values.Status === "Document_Loaded"
          ) {
            // configureEditor();
            return;
          } else if (
            JSON.parse(event.data).MessageId === "Clicked_Button" &&
            JSON.parse(event.data).Values.Id === "FS_BTN"
          ) {
            // handleClosefile();
            handleOpenCollab();
          } else if (JSON.parse(event.data).MessageId === "Views_List") {
            const viewList = JSON.parse(event.data).Values;
            const isActiveView: boolean = viewList.some((itm: any) => {
              return itm.ReadOnly === "0" && itm.IsCurrentView;
            });
            if (isActiveView) {
              setAutoSaveMode(true);
            } else {
              setAutoSaveMode(false);
            }

            if (viewList.length === 1 && isActiveView) {
              TRConfig(TrackChangeMethods.DEACTIVATE);
              TRConfig(TrackChangeMethods.SHOW);
              TRConfig(TrackChangeMethods.SPELLCHECK);
            }
          }
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseCollab = () => {
    handleClosefile();
    setToFullscreen(false);
    setTarget("collabora-online-viewer");
  };
  const handleOpenCollab = () => {
    handleClosefile();
    setTarget("collabora-online-viewer-fs");
    setToFullscreen(true);
  };

  const reloadDocument = () => {
    const formformExists: any = document.getElementById(
      "collabora-submit-form"
    );

    if (formformExists) {
      formformExists.submit();
    }
  };

  const handleSave = () => {
    const obj = {
      MessageId: "Action_Save",
      Values: {
        DontTerminateEdit: true,
        DontSaveIfUnmodified: true,
        Notify: true,
      },
    };
    if (autoSaveMode === true) {
      window.frames[target].postMessage(JSON.stringify(obj), "*");
    }
    return;
  };

  const handleClosefile = () => {
    const obj = { MessageId: "Action_Close", Values: null };
    window.frames[target].postMessage(JSON.stringify(obj), "*");
    return;
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (autoSaveMode) {
      intervalId = setInterval(() => handleSave(), 1000);
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSaveMode]);

  useEffect(() => {
    // Dispatch the validate action when the component mounts
    // @ts-ignore
    dispatch(validate());
  }, [dispatch]);

  useEffect(() => {
    const fetchdata = async () => {
      if (id !== undefined) {
        const assignment = await getStudentAssignment(id);
        setData({ ...data, assignment: assignment.data });
      }
    };
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (Object.keys(data.assignment).length !== 0) {
      if (data.assignment.submissions.length !== 0) {
        setFileId(data.assignment.submissions[0].id);
        setSubmissionId(data.assignment.submissions[0].id);
        const exp = data.assignment.submissions[0].status === "EXCEPTION";
        setException(exp);
      }
    }
  }, [data]);

  useEffect(() => {
    if (fileId !== "" && target === "collabora-online-viewer") {
      reloadDocument();
    }
  }, [fileId, target]);

  const handleStart = async () => {
    if (id !== undefined) {
      try {
        const assignment = await newDocument(id);
        setData({ ...data, assignment: assignment.data });
      } catch (error: any) {
        const messages =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(
          setSnackbarMessage({
            type: AlertTypes.ERROR,
            showSnackBar: true,
            message: messages,
          })
        );
      }
    }
  };

  const handleAccept = async () => {
    TRConfig(TrackChangeMethods.FINALIZE);
    return new Promise((resolve) => {
      setTimeout(resolve, 5000);
    });
  };

  const handleSubmit = async (draftId: string) => {
    if (id !== undefined) {
      dispatch(setIsLoading(apiIds.SUBMIT));
      try {
        if (data.assignment.autoAccept) {
          try {
            await handleAccept();
          } catch {
            return;
          }
        }
        const assignment = await submission(submissionId, draftId);
        setData({ ...data, assignment: assignment.data });
        setTimeout(() => reloadDocument(), 500);
        dispatch(
          setSnackbarMessage({
            type: AlertTypes.SUCCESS,
            showSnackBar: true,
            message: `You have made a submission!`,
          })
        );
      } finally {
        dispatch(setIsLoaded(apiIds.SUBMIT));
      }
    }
  };

  return (
    <Main>
      {Object.keys(data.assignment).length !== 0 && (
        <>
          <Grid container spacing={3}>
            <Details
              title={data.assignment.title}
              description={data.assignment.description}
              dueDate={toTimeZoneSimple(data.assignment.endDate.toString())}
              startDate={data.assignment.startDate}
              dueDateAMPM={toTimeZoneSimple(data.assignment.endDate.toString())}
              deadlineEnforcement={
                data.assignment.preventAfterDueDate ? "Yes" : "No"
              }
              autoAccept={data.assignment.autoAccept}
              autoSubmission={data.assignment.autoSubmission ? "Yes" : "No"}
              timelines={data.assignment.timeline}
              weight={data.assignment.pointValue}
              deadlines={data.assignment.deadlines}
              dispatch={dispatch}
              handleStart={handleStart}
              handleSubmit={handleSubmit}
              feedbacks={
                data.assignment.submissions.length !== 0
                  ? data.assignment.submissions[0].feedbacks
                  : []
              }
              exception={exception}
              submissionId={submissionId}
              setFileId={setFileId}
              showAnalytics={data.assignment.showAnalytics}
              handleAnalyticsOpen={handleAnalyticsOpen}
            />
          </Grid>
          {fileId !== "" && (
            <>
              <form
                action={EDITOR_URL + fileId}
                encType="multipart/form-data"
                method="post"
                target={target}
                id="collabora-submit-form"
              >
                <input
                  name="access_token"
                  value={authToken()}
                  type="hidden"
                  id="access-token"
                />

                <input
                  name="css_variables"
                  value="--color-main-text=#000;--color-text-dark=#000;--color-text-darker=#000;--color-text-lighter=#000;--color-main-background=#fff;--color-background-dark=#fff;--color-background-darker=#fff;--color-background-lighter=#fff;"
                  type="hidden"
                />

                <input
                  name="ui_defaults"
                  value="UIMode=tabbed;TextRuler=false;TextSidebar=false;"
                  type="hidden"
                />
              </form>
              <Grid item xs={12} component={Paper}>
                <Container
                  maxWidth="xl"
                  sx={{
                    contain: "layout",
                    height: ["inherit ", "90vh"],
                    mt: 2,
                  }}
                  disableGutters={true}
                >
                  <iframe
                    title="Collabora Online Viewer"
                    id="collabora-online-viewer"
                    name="collabora-online-viewer"
                    allowFullScreen={true}
                  ></iframe>
                </Container>
              </Grid>

              <FullScreen
                fsOpen={openFScollabora}
                handleFsClose={handleCloseCollab}
                target={target}
              />
            </>
          )}
        </>
      )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={showLoader()}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Analytics
        open={analyticsopen}
        handleClose={handleAnalyticsClose}
        data={analyticsData}
        setAnalyticsData={setAnalyticsData}
        submissionId={submissionId}
      />
    </Main>
  );
};

export default StudentAssignmentDashboard;
