export type TabType = "DETAILS" | "STUDENTS" | "ASSIGNMENTS" | "ANALYTICS";
type CustomTab = {
  [key in TabType]: TabType;
};
export const tabs: CustomTab = {
  DETAILS: "DETAILS",
  STUDENTS: "STUDENTS",
  ASSIGNMENTS: "ASSIGNMENTS",
  ANALYTICS: "ANALYTICS",
};
