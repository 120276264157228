import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "../../services/auth-header";
import { Class } from "../../services/models/classes.model";

const HOST = process.env.REACT_APP_API_URL;
const ASSISTANT = "api/assistant";
const APIS = { CLASS_LIST:"class"}


export const getClassList = async() =>{
    const axiosConfig: AxiosRequestConfig = {
      headers: authHeader(),
      method: "GET",
      url: `${HOST}/${ASSISTANT}/${APIS.CLASS_LIST}`,
      params: {},
      data: {},
  
    };
    try {
      const apiCall: AxiosResponse<Class[]> = await axios(axiosConfig);
      return apiCall;
    } catch (err) {
      throw err;
    }
  }