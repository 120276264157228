import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import AbcIcon from '@mui/icons-material/Abc';
import { OauthLoginProps } from "./model";


export const oauthLogins:OauthLoginProps[] = [
  { color: "black", title: "GITHUB", link: "/oauth2/authorization/github", icon: <GitHubIcon /> },
  { color: "black", title: "GOOGLE", link: "/oauth2/authorization/google", icon: <GoogleIcon /> },
  { color: "black", title: "LIBRETEXTS", link: "/oauth2/authorization/cas", icon: <AbcIcon /> },
];