export const apiIds = {
  getClassDetails: "getClassDetails",
  updateClassDetails: "updateClassDetails",
  deleteAssignment: "deleteAssignment",
  createAssignment: "createAssignment",
  updateAssignment: "updateAssignment",
  deleteStudent: "deleteStudent",
  updateAssignmentVisibility: "updateAssignmentVisibility",
  updateAssignmentStopStatus: "updateAssignmentStopStatus",
  updateDraftPeerReviewDeadline: "updateDraftPeerReviewDeadline",
  addTeachingAssistant:"addTeachingAssistant"
};



export const GET_CLASS_DETAILS_SUCCESS = "GET_CLASS_DETAILS_SUCCESS";
export const UPDATE_CLASS_DETAILS_SUCCESS = "UPDATE_CLASS_DETAILS_SUCCESS";
export const DELETE_ASSIGNMENT_SUCCESS = "DELETE_ASSIGNMENT_SUCCESS";
export const CREATE_ASSIGNMENT_SUCCESS = "CREATE_ASSIGNMENT_SUCCESS";
export const UPDATE_ASSIGNMENT_SUCCESS = "UPDATE_ASSIGNMENT_SUCCESS";

export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const UPDATE_ASSIGNMENT_VISIBILITY_SUCCESS =
  "UPDATE_ASSIGNMENT_VISIBILITY_SUCCESS";
export const UPDATE_ASSIGNMENT_STOP_SUCCESS = "UPDATE_ASSIGNMENT_STOP_SUCCESS";

export const UPDATE_DRAFT_PEERREVIEW_DEADLINE_SUCCESS =
  "UPDATE_DRAFT_PEERREVIEW_DEADLINE_SUCCESS";

export const ADD_TEACHING_ASSISTANT_SUCCESS = "ADD_TEACHING_ASSISTANT_SUCCESS";

