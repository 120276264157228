// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/scatterplot
import React, { useEffect, useState } from "react";
import { getChangeDistGraph } from "../../services/classes.service";

interface MyResponsiveScatterPlotProps {
  subid: string;
}

const style: React.CSSProperties = {
  maxWidth:"80vw",
  maxHeight:"80vh",
};

const ChangeDistGraph: React.FC<MyResponsiveScatterPlotProps> = ({ subid }) => {
  const [svgData, setSvgData] = useState<string>("");

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        const svg = await getChangeDistGraph(subid);
        setSvgData(svg);
      } catch (error) {
        console.error("Error fetching change distribution graph:", error);
      }
    };

    fetchGraphData();
  }, [subid]);
  return (
    <div >
      {svgData ? (
        <div dangerouslySetInnerHTML={{ __html: svgData }} style={style}/>
      ) : (
        <p>Loading graph...</p>
      )}
    </div>
  );
};

export default ChangeDistGraph;
