export interface AnalyticsOpenProps {
  student: boolean;
  assignment: boolean;
}

export const initAnalyticsOpenState: AnalyticsOpenProps = {
  student: false,
  assignment: false,
};

export interface AnalyticsDialogProps {
  open: boolean;
  handleClose: () => void;
}

export type TabType =
  | "Heatmap"
  | "Hourly Activity"
  | "Words Changed"
  | "Word Change"
  | "WPM Averages"
  | "Assignment Activity"
  | "EssayMap";

//   | "Class Total Time"
//   | "Student Total Time"
//   | "Time Averages"
//   | "WPM Averages"
//   | "Assignment Time"

export const assignmentTabs: TabType[] = [
  "Heatmap",
  "Hourly Activity",
  "Words Changed",
];

export interface AssignmentAnalyticsProps {
  currentTab: TabType;
  heatmapData: any[] | null;
  hourlyActivityData: any[] | null;
  wordsChangedData: any[] | null;
}

export const initAssignmentAnalytics: AssignmentAnalyticsProps = {
  currentTab: "Heatmap",
  heatmapData: null,
  hourlyActivityData: null,
  wordsChangedData: null,
};

export const studentTabs: TabType[] = [
  "Word Change",
  "WPM Averages",
  "Assignment Activity",
  "Hourly Activity",
  "Heatmap",
  "EssayMap",
];

export interface StudentAnalyticsProps {
  currentTab: TabType;
  wordChangeData: any[] | null;
  wordAverageData: any[] | null;
  assignmentActivityData: any[] | null;
  hourlyActivityData: any[] | null;
  heatmapData: any[] | null;
  essayMapData: string | null;
}

export const initStudentAnalytics: StudentAnalyticsProps = {
  currentTab: "Word Change",
  wordChangeData: null,
  wordAverageData: null,
  assignmentActivityData: null,
  hourlyActivityData: null,
  heatmapData: null,
  essayMapData: null,
};
