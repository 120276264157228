import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Main from "../../../elements/public/main";
import { oauth2loginUpdate } from "../../../actions/auth";
import { Alert } from "@mui/material";

const OauthRedirect: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<any>();
  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get("token");
  const error = queryParams.get("error");
  const redirect_url = queryParams.get("redirect_url") || "/profile";

  const updateToken = async () => {
    try {
      await dispatch(oauth2loginUpdate(token)); // Waits for the async operation to complete
      window.location.replace(redirect_url); // Executes after completion
    } catch (error) {
      console.error("Error during token update:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };
  updateToken();

  return <Main>
    {token && <>Wait for moment.</>}
    {error && <Alert severity="error" variant="filled">{error}</Alert>}
  </Main>;
};

export default OauthRedirect;
