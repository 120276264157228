import { Button } from '@mui/material';
import React from 'react';
import { oauthLogins } from '../constants';

interface OauthLinksProps{
    inner_redirect : string;
}

const OauthLinks: React.FC<OauthLinksProps> = ({inner_redirect}) => {
    const host = process.env.REACT_APP_API_URL;
    const uiUrl = process.env.REACT_APP_PUBLIC_URL || host;
    const redirect = (link: string) => {
        window.location.replace(`${host}${link}?redirect_uri=${uiUrl}/oauth2/redirect?redirect_url=${inner_redirect}`)
    }


    return (
        <>
            {oauthLogins.map(c => (<Button
                fullWidth
                type="button"
                onClick={() => redirect(c.link)}
                variant="outlined"
                sx={{ color: "black", mt: 2 }}
                endIcon={c.icon}
            >{c.title}</Button>))}

        </>
    );
};

export default OauthLinks;