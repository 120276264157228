export type ShowAnalyticsType = "NEVER" | "AFTER_GRADE" | "ALWAYS";

export interface ShowAnalyticsProps {
  type: ShowAnalyticsType;
  label: string;
}

export type TabType =
  | "DETAILS"
  | "STUDENTS"
  | "TEACHING ASSISTANTS"
  | "ASSIGNMENTS"
  | "ANALYTICS";
type CustomTab = {
  [key in TabType]: TabType;
};
export const tabs: CustomTab = {
  DETAILS: "DETAILS",
  STUDENTS: "STUDENTS",
  "TEACHING ASSISTANTS": "TEACHING ASSISTANTS",
  ASSIGNMENTS: "ASSIGNMENTS",
  ANALYTICS: "ANALYTICS",
};

