import React, { useEffect } from 'react';
import Main from '../../elements/public/main';


const InstituteOidc:React.FC = () => {
    const host = process.env.REACT_APP_API_URL;
    const uiUrl = process.env.REACT_APP_PUBLIC_URL || host;
    const instLogin = process.env.REACT_APP_INSTITUTE_OIDC
    const instLoginLink = process.env.REACT_APP_INSTITUTE_LINK
    const queryParams = new URLSearchParams(window.location.search);
    const redirect_url = queryParams.get("redirect_url") || "/profile";

    useEffect(() => {
        const redirect = () => {
        
             if (instLoginLink === "false") {

                window.location.replace(`/login`);
                return;
            }
            window.location.replace(`${host}${instLoginLink}?redirect_uri=${uiUrl}/oauth2/redirect?redirect_url=${redirect_url}`);
        };
        redirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [host, instLogin, instLoginLink])

    return (
        <Main>
            Login into Libretexts.
        </Main>
    );
};

export default InstituteOidc;