import React, { useEffect, useState } from 'react';
import {
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    FormHelperText,
    Box,
    Tooltip,
    Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchUser } from './services';
import { setSnackbarMessage } from '../../../../store/UIActions/Snackbar/actions';
import { AlertTypes } from '../../../../store/UIActions/Snackbar/model';
import { useDispatch } from 'react-redux';
import { addTeachingAssistantAction } from '../../../../store/ClassDetails/actions';
import { useSelector } from 'react-redux';

const UserSearch: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [user, setUser] = useState<any | null>(null);

    const classId: string = useSelector(
        (state: any) => state.classDetails.id
      );

    const dispatch = useDispatch()

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!email || email.length === 0) {
            setUser(null);
        }

        // Basic email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        setError(null);
        try {
            const response = await searchUser(email);
            setUser(response.data)
        }
        catch (e: any) {
            setUser(null);
            let errorMessage = "";

            if (e.response && e.response.data && e.response.data.message) {
                errorMessage = e.response.data.message;
            } else if (e.message) {
                errorMessage = e.message;
            }
            dispatch(
                setSnackbarMessage({
                    type: AlertTypes.ERROR,
                    showSnackBar: true,
                    message: errorMessage,
                })
            );
        }

    };

    useEffect(() => {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
            setError(null);
        }
    }, [email]);

    const addAssistant = async ()=>{
        // @ts-ignore
        dispatch(addTeachingAssistantAction(user,classId));
    }


    return (
        <Grid container component="form" onSubmit={handleSubmit} spacing={2}>
            <Grid item xs={12} md={8}>
                <OutlinedInput
                    fullWidth
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Update email state
                    placeholder="Enter email"
                    error={!!error} // Highlight the input field on error
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip title="Search">
                            <IconButton type="submit" aria-label="submit search" edge="end">
                                <SearchIcon />
                            </IconButton></Tooltip>
                        </InputAdornment>
                    }
                />
                {error && (
                    <FormHelperText error>
                        {error}
                    </FormHelperText>
                )}
            </Grid>
            {user && (<Grid item xs={12} md={8}>
                <Box sx={{ p: 2, pb: 0, border: "1px solid grey" }}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6} sx={{ bgcolor: "text.hint" }}>First name</Grid>
                        <Grid item xs={12} md={6}>{user.firstName}</Grid>
                        <Grid item xs={12} md={6} sx={{ bgcolor: "text.hint" }}>Last name</Grid>
                        <Grid item xs={12} md={6}>{user.lastName}</Grid>
                        <Grid item xs={12} md={6} sx={{ bgcolor: "text.hint" }}>Email</Grid>
                        <Grid item xs={12} md={6}>{user.email}</Grid>
                        <Grid item xs={12} md={6} sx={{ bgcolor: "text.hint" }}>Active</Grid>
                        <Grid item xs={12} md={6}>{user.confirmed ? "YES" : "NO"}</Grid>
                        <Grid item xs={12} md={6} sx={{ bgcolor: "text.hint" }}></Grid>
                        <Grid item xs={12} md={6}><Button fullWidth variant='contained' sx={{mr:3}} onClick={addAssistant} >Add</Button></Grid>
                    </Grid>
                </Box>
            </Grid>)}
        </Grid>
    );
};

export default UserSearch;
