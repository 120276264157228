import { User } from "./models/auth-token.model";

export default function authHeader(): any {
  // @ts-ignore adding this to ignore the annoying ts error
  const user: User = JSON.parse(localStorage.getItem("user"));

  if (user && user.accessToken) {
    // For Spring Boot back-end
    return { Authorization: "Bearer " + user.accessToken };

    // for Node.js Express back-end
    // return { "x-access-token": user.accessToken };
  } else {
    return {};
  }
}


export function authHeadertoken(token): any {
  // @ts-ignore adding this to ignore the annoying ts error

  if (token) {
    // For Spring Boot back-end
    return { Authorization: "Bearer " + token};

    // for Node.js Express back-end
    // return { "x-access-token": user.accessToken };
  } else {
    return {};
  }
}
