export const API_IDS = { CONTACT_US: "CONTACT_US" };

export const MESSAGES = {
  CONTACT: {
    SUCCESS: `Your message has been sent successfully.`,
    ERROR: `An error occurred while we were sending your message. Please try again later.`,
  },
};

export const LINKS = [
  { caption: "Register", link: "/register", target: "_self" },
  { caption: "Login", link: "/login", target: "_self" },
  {
    caption: "Terms and Conditions",
    link: "/legal/termsandconditions",
    target: "_blank",
  },
  {
    caption: "Privacy Policy",
    link: "/legal/privacy",
    target: "_blank",
  },
 
  {
    caption: "North American Version",
    link: "https://antecedentwriting.com/",
    target: "_blank",
  },
  {
    caption: "European Version",
    link: " https://antecedent.egroupware.org/",
    target: "_blank",
  },
];

export const SectionIds = {
  ABOUTUS: "aboutus",
  CONTACTUS: "contactus",
  TOP: "top",
  EVENT:"event",
  FEATURE:"feature",
};

export const EventDetails = [
  {
    title: "When AI detection isn’t enough",
    media:
      "https://cdn.antecedentwriting.com/wp-content/uploads/2024/05/webinar_antecedent_egroupware_cool_landing-2.png",
    desc: [
      "How Antecedent are protecting academic integrity.",
      "How Collabora Online enables collaboration between students and educators.",
      "EGroupware’s comprehensive suite of solutions to enable students engagement and learning.",
    ],
    link: "https://mautic.collaboraoffice.com/webinar-19-06-24-antecedent-egroupware",
  },
];

export const FeatureDetails: string[] = [
  "Designed around an iterative drafting process",
  "Supports effort/labor-conscious grading",
  "Ensures students honor the process",
  "Collaborate with students in a flexible, structured environment",
  "Writing analytics, not AI detection",
  "Run on your institution’s server and break free of (ed-)tech giants",
  "Designed and owned by teachers, not tech bros",
];

export const PrivacyFeatureDetails: string[] = [
  "We do not sell data to third parties",
  "Self-hosted options that protect student data",
  "GDPR-compliant version for Europe",
  "No need to submit student work to databases of for-profit ed-tech companies indefinitely",
  "No keystroke logging",
];
// https://www.egroupware.org/wp-content/uploads/EGroupware-Logo-ohne-Rand-Dateioptimiert.svg
// https://cdn.antecedentwriting.com/wp-content/uploads/2024/05/collabora-online-logo.png
export const PartnerDetails = [
  {
    Link: "https://www.egroupware.org/",
    Media:
      "https://www.egroupware.org/wp-content/uploads/EGroupware-Logo-ohne-Rand-Dateioptimiert.svg",
    caption: "EGroupware",
  },
  {
    Link: "https://www.collaboraoffice.com/",
    Media:
      "https://cdn.antecedentwriting.com/wp-content/uploads/2024/05/collabora-online-logo.png",
    caption: "Collabora Office",
  },
  {
    Link: "https://libretexts.org/",
    Media:
      "https://cdn.antecedentwriting.com/wp-content/uploads/2024/10/libretexts_logo_main_bluepng-1.png",
    caption: "Libretexts",
  },
];
