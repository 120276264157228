import React from "react";
import {
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StudentRow from "./StudentsRow";
import {
  Student,
} from "../../utils/model";
import { gradeType } from "../../../instructor/CreateCourse/model";



interface StudentListProps {
  students?: Student[];
  assignments?: any;
  gradeMethod : gradeType;


}
function StudentsList(props: StudentListProps) {

  const gradeMethod = props?.gradeMethod;


  return (
    <>
      {props.students && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Student Name</TableCell>
                <TableCell align="right">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* TODO add interface */}
              {props.students.map((row: Student) => (
                <StudentRow
                  key={row.id}
                  row={row}
                  assignments={props.assignments}
                 
                  gradeMethod={gradeMethod}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!props.students && (
        <Alert severity="error">No student registered yet</Alert>
      )}

     
    </>
  );
}

export default StudentsList;
