export type gradeType = "Numeric" | "Letter" | "Pass/Fail";
export const courseGradeTypes: gradeType[] = ["Numeric", "Letter", ];

export type LetterGradesType =
  | ""
  | "F"
  | "D-"
  | "D"
  | "D+"
  | "C-"
  | "C"
  | "C+"
  | "B-"
  | "B"
  | "B+"
  | "A-"
  | "A"
  | "A+";

export interface LetterGradeProps {
  value: LetterGradesType;
  selected: boolean;
}

export const initLetterGrades: LetterGradeProps[] = [
  { value: "F", selected: true },
  { value: "D-", selected: false },
  { value: "D", selected: true },
  { value: "D+", selected: false },
  { value: "C-", selected: false },
  { value: "C", selected: true },
  { value: "C+", selected: false },
  { value: "B-", selected: false },
  { value: "B", selected: true },
  { value: "B+", selected: false },
  { value: "A-", selected: false },
  { value: "A", selected: true },
  { value: "A+", selected: false },
];
