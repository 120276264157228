import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "../../../../services/auth-header";

const HOST = process.env.REACT_APP_API_URL;
const ASSISTANT = "api/assistant";
const APIS = { SEARCH_USER:"searchuser", ADD_ASSISTANT:"addta"}

export const searchUser = async(email:string) =>{
    const axiosConfig: AxiosRequestConfig = {
        headers: authHeader(),
        method: "POST",
        url: `${HOST}/${ASSISTANT}/${APIS.SEARCH_USER}`,
        params: {email},
        data: {},
    
      };
      try {
        const apiCall: AxiosResponse<any> = await axios(axiosConfig);
        return apiCall;
      } catch (err) {
        throw err;
      }
}

export const addAssistant = async(classId:string,userId:string) =>{
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${ASSISTANT}/${APIS.ADD_ASSISTANT}`,
    params: {classId,userId},
    data: {},

  };
  try {
    const apiCall: AxiosResponse<any> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}