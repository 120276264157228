import React, { useEffect, useState } from "react";
import {
  AnalyticsDialogProps,
  AssignmentAnalyticsProps,
  assignmentTabs,
  initAssignmentAnalytics,
  TabType,
} from "../model";
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import {
  getassignmentHeatMapLogs,
  getassignmentLineGraphLogs,
  getassignmentTimeSeriesLogs,
} from "../../../../../services/classes.service";
import Heatmap from "../../../ClassDetails/Assignments/Analytics/Graphs/Heatmap";
import LineGraph from "../../../ClassDetails/Assignments/Analytics/Graphs/LineGraph";
import { getTimeSeriesTotal } from "../../../ClassDetails/Assignments/Analytics/services";
import TimeSeries from "../../../ClassDetails/Assignments/Analytics/Graphs/TimeSeries";

const AssignmentAnalyticsDashboard: React.FC<AnalyticsDialogProps> = ({
  open,
  handleClose,
}) => {
  const [state, setState] = useState<AssignmentAnalyticsProps>(
    initAssignmentAnalytics
  );
  const { assignmentId, classTitle, assignmentTitle } = useSelector(
    (state: any) => state.assignment.submissionData
  );

  const { currentTab } = state;
  useEffect(() => {
    const getHeadmapData = async () => {
      try {
        const heatMapLogs = await getassignmentHeatMapLogs(assignmentId);
        return { heatmapData: JSON.parse(heatMapLogs.data) };
      } catch {}
    };

    const getHourlyActivityData = async () => {
      try {
        const lineGraphLogs = await getassignmentLineGraphLogs(assignmentId);
        return {
          hourlyActivityData: JSON.parse(lineGraphLogs.data),
        };
      } catch {}
    };
    const getWordsChangedData = async () => {
      try {
        const timeSeriesLogs = await getassignmentTimeSeriesLogs(assignmentId);
        return {
          wordsChangedData: [
            { id: "Words Changed", data: JSON.parse(timeSeriesLogs.data) },
          ],
        };
      } catch {}
    };
    const methods = [
      getHeadmapData,
      getHourlyActivityData,
      getWordsChangedData,
    ];

    const fetchData = async () => {
      if (assignmentId) {
        for (const method of methods) {
          const result = await method();
          setState((prev) => ({ ...prev, ...result }));

          // Merge new data with the existing state
        }
      }
    };
    fetchData();
  }, [assignmentId]);

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Assignment Analytics - {classTitle}, {assignmentTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {" "}
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            minHeight: "75vh",
          }}
        >
          <Tabs
            orientation="vertical"
            sx={{ borderRight: 1, borderColor: "divider" }}
            variant="scrollable"
            value={currentTab}
            onChange={(event: React.SyntheticEvent, newValue: TabType) =>
              setState({ ...state, currentTab: newValue })
            }
          >
            {assignmentTabs.map((tab: TabType, index: number) => (
              <Tab
                key={`analytic_tab_${index}`}
                id={`analytic_tab_${index}`}
                aria-controls={`simple-tabpanel-${index}`}
                label={tab}
                value={tab}
              />
            ))}
          </Tabs>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                ml: 2,
                maxHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
              }}
            >
              {currentTab === "Heatmap" && <Heatmap data={state.heatmapData} />}
              {currentTab === "Hourly Activity" && (
                <LineGraph
                  data={
                    state.hourlyActivityData === null
                      ? state.hourlyActivityData
                      : getTimeSeriesTotal(state.hourlyActivityData)
                  }
                />
              )}
              {currentTab === "Words Changed" && (
                <TimeSeries data={state.wordsChangedData} />
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AssignmentAnalyticsDashboard;
