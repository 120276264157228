import React from 'react';

import UserSearch from './UserSearch';
import AssistantList from './AssistantList';

const TaPanel:React.FC = () => {
    return (
       <>
       <UserSearch />
       <AssistantList />
       </>
    );
};

export default TaPanel;