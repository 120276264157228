
import { Alert, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const AssistantList = () => {

    const assistants: any[] = useSelector(
        (state: any) => state.classDetails.assistants
    );
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
            {assistants.length === 0 && <Alert severity="info">Search your assistant with email address</Alert>}
            {assistants.length > 0 && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assistants.map(c=>(
                        <TableRow key={c.id}>
                            <TableCell>{c.firstName}</TableCell>
                            <TableCell>{c.lastName}</TableCell>
                            <TableCell>{c.email}</TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
            )
            }
        </Grid></Grid>
    );
};

export default AssistantList;