import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);


export function formatDateToCustomString(inputDate: string): string {
    return dayjs(inputDate)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 30)
      .set('millisecond', 0)
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  }