import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import {
  GraphsProps,
  initGraphProps,
  studentTabs,
  TabType,
  tooltipsText,
} from "./models";
import { useSelector } from "react-redux";
import {
  analyzeSubmission,
  getStudentEssayMap,
  getStudentHeatMapLogs,
  getStudentLineGraphLogs,
  getStudentTimeSeriesLogs,
} from "../../../../../services/classes.service";
import Heatmap from "./Graphs/Heatmap";
import LineGraph from "./Graphs/LineGraph";
import TimeSeries from "./Graphs/TimeSeries";
import PieChart from "./Graphs/PieChart";
import { Assignment, Student } from "../../../../../store/ClassDetails/model";
import dayjs from "dayjs";
import WordChangeGraph from "./Graphs/WordChangeGraph";
import { getAvergeWord, getTimeSeriesTotal } from "./services";
import Histogram from "./Graphs/Histogram";
const style: React.CSSProperties = {
  maxWidth: "95vw",
  maxHeight: "80vh",
};
interface AnalyticsProps {
  studentId: string;
  studentName: string;
  handleClose: () => void;
}
const StudentDashboard: React.FC<AnalyticsProps> = ({
  studentId,
  studentName,
  handleClose,
}) => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState<TabType>("Student Total Time");
  const [data, setData] = useState<GraphsProps>(initGraphProps);
  const id = useSelector<any, string | undefined>(
    (state) => state.classDetails.id
  );

  const students: Student[] = useSelector((state: any) =>
    state.classDetails.students.filter((c: Student) => c.id === studentId)
  );

  const assignments: Assignment[] = useSelector(
    (state: any) => state.classDetails.assignments
  );

  const timezone: string = useSelector(
    (state: any) => state.classDetails.classTimeZone
  );

  const handleLocalClose = () => {
    setData(initGraphProps);
    setCurrentTab("Student Total Time");
    setTimeout(handleClose, 1);
  };

  const abortController = new AbortController(); // Create AbortController
  const signal = abortController.signal;
  useEffect(() => {
    const getHeatdmapData = async () => {
      if (id) {
        try {
          const heatMapLogs = await getStudentHeatMapLogs(
            id,
            studentId,
            signal
          );
          return { HeatmapProps: JSON.parse(heatMapLogs.data) };
        } catch {}
      }
    };

    const getLineGraphData = async () => {
      if (id) {
        try {
          const lineGraphLogs = await getStudentLineGraphLogs(
            id,
            studentId,
            signal
          );
          return { lineGraphProps: JSON.parse(lineGraphLogs.data) };
        } catch {}
      }
    };
    const getTimeSeriesData = async () => {
      if (id) {
        try {
          const timeSeriesLogs = await getStudentTimeSeriesLogs(
            id,
            studentId,
            signal
          );
          return {
            TimeSeriesProps: timeSeriesLogs.data.filter(
              (c) => c.data.length > 0
            ),
          };
        } catch {}
      }
    };

    const getWordChangeData = async () => {
      const promises = students[0].submissions.map(async (item: any) => {
        try {
          const response = await analyzeSubmission(item.submissionId, signal);
          return {
            id: getAssignmentTitle(item.assignmentId),
            data: response.data.map((c) => {
              return { x: dayjs().format(`${c.x}`), y: c.y };
            }),
          };
        } catch (error) {
          return undefined;
        }
      });

      // Wait for all promises to resolve and filter out undefined values

      const results = (await Promise.all(promises)).filter(
        (c) => c !== undefined
      );
      return { WordChangeProps: results };
    };

    const getEssayMapData = async () => {
      const ids = students[0].submissions.map((c) => c.submissionId);
      const titles = students[0].submissions.map((c) =>
        getAssignmentTitle(c.assignmentId)
      );
      try {
        const response = await getStudentEssayMap(
          ids,
          titles,
          timezone,
          signal
        );

        return { EssayMapProps: response.data };
      } catch {}
    };

    const methods = [
      getWordChangeData,
      getTimeSeriesData,
      getLineGraphData,
      getHeatdmapData,
      getEssayMapData,
    ];
    const fetchData = async () => {
      if (studentId && studentId !== "" && studentId !== null) {
        for (const method of methods) {
          const result = await method();
          setData((prev) => ({ ...prev, ...result }));

          // Merge new data with the existing state
        }
      }
    };

    fetchData();
    return () => {
      abortController.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId, id]);



  const getAssignmentTitle = (id: string): string => {
    try {
      const title = assignments.find((c) => c.id === id)?.title;
      return title ? title : id;
    } catch {
      return id;
    }
  };

  const getPieChartData = () => {
    try {
      const pieData = students[0].submissions
        .filter((c) => c.totalTime > 0)
        .map((sub) => {
          return {
            id: getAssignmentTitle(sub.assignmentId),
            label: getAssignmentTitle(sub.assignmentId),
            value: sub.totalTime,
          };
        });
      return pieData;
    } catch {
      return undefined;
    }
  };

  const isDisabled = (tab: TabType): boolean => {
    switch (tab) {
      case "Heatmap":
        return data.HeatmapProps === null ? true : false;

      case "Assignment Activity":
        return data.TimeSeriesProps === null ? true : false;
      case "Hourly Activity":
        return data.lineGraphProps === null ? true : false;
      case "Word Change":
      case "WPM Averages":
        return data.WordChangeProps === null ? true : false;
      case "EssayMap":
        return data.EssayMapProps === null ? true : false;
      default:
        return false;
    }
  };

  return (
    <Dialog
      open={studentId !== ""}
      onClose={handleLocalClose}
      fullWidth
      fullScreen
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleLocalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1, color: "black" }}
            variant="h6"
            component="div"
          >
            Student Analytics - {studentName}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            minHeight: "75vh",
          }}
        >
          <Tabs
            orientation="vertical"
            sx={{ borderRight: 1, borderColor: "divider" }}
            variant="scrollable"
            value={currentTab}
            onChange={(event: React.SyntheticEvent, newValue: TabType) =>
              setCurrentTab(newValue)
            }
          >
            {studentTabs.map((tab: TabType, index: number) => (
              <Tooltip title={tooltipsText[tab]} placement="right-end">
                <Tab
                  key={`analytic_tab_${index}`}
                  id={`analytic_tab_${index}`}
                  aria-controls={`simple-tabpanel-${index}`}
                  label={
                    <>
                      {tab}{" "}
                      {isDisabled(tab) && (
                        <CircularProgress color="inherit" size={16} />
                      )}
                    </>
                  }
                  value={tab}
                  sx={{
                    color:
                      currentTab === tab
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                  }}
                  disabled={isDisabled(tab)}
                />
              </Tooltip>
            ))}
          </Tabs>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                ml: 2,
                maxHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
              }}
            >
              {currentTab === "Heatmap" && <Heatmap data={data.HeatmapProps} />}
              {currentTab === "Hourly Activity" && (
                <LineGraph
                  data={
                    data.lineGraphProps === null
                      ? data.lineGraphProps
                      : getTimeSeriesTotal(data.lineGraphProps)
                  }
                />
              )}

              {currentTab === "Assignment Activity" && (
                <TimeSeries data={data.TimeSeriesProps} />
              )}
              {currentTab === "Student Total Time" && getPieChartData() && (
                <PieChart data={getPieChartData()} />
              )}
              {currentTab === "Word Change" && data.WordChangeProps && (
                <>
                  <WordChangeGraph data={data.WordChangeProps} maxY={200} />
                </>
              )}

              {currentTab === "WPM Averages" && data.WordChangeProps && (
                <>
                  <Histogram data={getAvergeWord(data.WordChangeProps)} />
                </>
              )}

              {currentTab === "EssayMap" && data.EssayMapProps && (
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: data.EssayMapProps }}
                    style={style}
                  />
                  <>Legend</>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default StudentDashboard;
