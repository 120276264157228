import React, { useState } from 'react';
import { Assignment, Student } from '../../../../store/ClassDetails/model';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Analytics from '../../../instructor/ClassDetails/Assignments/Analytics/Dashboard';
import AssignmentRow from './AssignmentRow';
interface AssignmentsProps {
    assignments: Assignment[];
    classTimeZone: string;
    students: Student[];

}
const Assignments: React.FC<AssignmentsProps> = (props) => {
    const getMappedAssignmentStudents = () => {
        const list: any[] = props.assignments?.map((assignment) => {
            let assignStudents: any = {
                studentSubmissions: [],
            };
            props.students.map((student) => {
                const submissionsFound = student.submissions.filter(
                    (submission) => submission.assignmentId === assignment.id
                );
                if (submissionsFound.length >= 1) {
                    assignStudents.studentSubmissions.push({
                        ...student,
                        submissionDetails: submissionsFound[0],
                    });
                }
                return assignStudents;
            });
            return {
                ...assignment,
                ...assignStudents,
            };
        });
        return list;
    };

    const [analyticId, setAnalyticId] = useState<string>("");


    return (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell align="left">Start Date</TableCell>
                  <TableCell align="left">Deadline</TableCell>
                  <TableCell align="left">Autosubmit</TableCell>
    
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getMappedAssignmentStudents().map(
                  (assignment: Assignment, index: number) => (
                    <AssignmentRow
                      key={index}
                      classTimeZone={props.classTimeZone}
                      assignment={assignment}
               
                      setAnalyticId={setAnalyticId}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Analytics assignmentId={analyticId} handleClose={()=>setAnalyticId("")}/>
        </>
      );
};

export default Assignments;